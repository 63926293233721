import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function DeleteDialogBox({cartId, deleteFeaturedCart}) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = (deleteFeaturedCart) => {
    const deleteCart = async () => {
      try {
        await deleteFeaturedCart(cartId);
        setOpen(false);
        // window.location = '/';
        window.location.reload();
      } catch (ex) {
        if (ex.response && ex.response.status === 404) {
          console.log('Cart already deleted.', ex) 
          window.location = '/';
        }
        console.log(ex); // TODO: take out? should be caught by axious interceptor
      }
    }
    deleteCart();
  };


  return (
    <React.Fragment>
      <div onClick={handleClickOpen} className="admin-btn admin-btn-delete">Delete</div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Delete Cart?"}</DialogTitle>

        <DialogActions>
          <Button onClick={() => handleDelete(deleteFeaturedCart)} color="black">
            Yes
          </Button>
          <Button onClick={handleClose} color="black" autoFocus>
            No
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}