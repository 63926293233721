import React, { useEffect } from 'react';
import { Checkbox, FormControlLabel, FormGroup, FormLabel, TextField, Button } from '@material-ui/core';


const canHelpWithItems = [
  'Hospitality for meetings',
  'Distribution of PR materials',
  'Art in local businesses',
  'Gallery sales substitute',
  'Teach or demonstrate art',
  'Special needs art instruction',
  'E-news',
  'Fundraising events',
  'Publicity',
  'Advertising',
  'Serve as a committee chair or member',
  'Serve on TAL board',
  'Teach workshop or classes',
  'Artist Studio Tour',
  'Lead plain air painting',
  'Website'
];


function MemberProfile({ familyMembership, hideProfile, history, inputChange, inputChange2, state }) {
  const { member, member2, errors, errors2 } = state;

  let memberFirstNameCap = '';
  let member2FirstNameCap = '';

  if (member.firstName !== '')
    memberFirstNameCap = member.firstName.charAt(0).toUpperCase() + member.firstName.slice(1);

  if (member2.firstName2 !== '')
    member2FirstNameCap = member2.firstName2.charAt(0).toUpperCase() + member2.firstName2.slice(1);


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    state.showProfile = false; // takes user back to beginning of form if hitting back arrow on orders page
    sessionStorage.setItem('formData', JSON.stringify(state));

    history.push({
      pathname: '/membership/order',
      memberInfo: state
    })
  }

  const textFieldsMember1 = (
    <React.Fragment>
      <TextField error={!!errors.website} helperText={errors.website} value={member.website} onChange={inputChange} id="website" name="website" type="text" label="Website"  style={{ marginTop: '1.5em', width: '100%' }}/><br/>
      <TextField error={!!errors.artMedium} helperText={errors.artMedium} value={member.artMedium} onChange={inputChange} id="artMedium" name="artMedium" type="text" label="Art Medium" multiline rows={3} rowsMax={10} style={{ marginTop: '1.5em', width: '100%' }} /><br/>
      <div className="infoText" style={{ marginTop: '2.5em', marginBottom: '0.5em' }}>List any Educational Background or Experience that would support our 501c3 organization, ie: law degree, grant writing experience, years experience in financial industry, etc</div>
      <TextField error={!!errors.educationExperience} helperText={errors.educationExperience} value={member.educationExperience} onChange={inputChange} id="educationExperience" name="educationExperience" type="text" label="Education / Experience"  multiline rows={3} rowsMax={10} style={{ width: '100%' }} /><br/>
    </React.Fragment>
  );

  const textFieldsMember2 = (
    <React.Fragment>
      <TextField error={!!errors2.website2} helperText={errors2.website2} value={member2.website2} onChange={inputChange2} id="website2" name="website2" type="text" label="Website"  style={{ marginTop: '1.5em', width: '100%' }}/><br/>
      <TextField error={!!errors2.artMedium2} helperText={errors2.artMedium2} value={member2.artMedium2} onChange={inputChange2} id="artMedium2" name="artMedium2" type="text" label="Art Medium" multiline rows={3} rowsMax={10} style={{ marginTop: '1.5em', width: '100%' }} /><br/>
      <div className="infoText" style={{ marginTop: '2.5em', marginBottom: '0.5em' }}>List any Educational Background or Experience that would support our 501c3 organization, ie: law degree, grant writing experience, years experience in financial industry, etc</div>
      <TextField error={!!errors2.educationExperience2} helperText={errors2.educationExperience2} value={member2.educationExperience2} onChange={inputChange2} id="educationExperience2" name="educationExperience2" type="text" label="Education / Experience"  multiline rows={3} rowsMax={10} style={{ width: '100%' }} /><br/>
    </React.Fragment>
  );

  return (
    <form className="membership-form" onSubmit={handleSubmit}>
      <div className="membership-form-col">
        
        <FormLabel style={{ fontSize: '1.1rem', fontWeight: 600, color: '#000' }} >
          { (!familyMembership) && <React.Fragment>{memberFirstNameCap}'s Art Profile</React.Fragment> }
          { (familyMembership) && <React.Fragment>{memberFirstNameCap}'s Art Profile</React.Fragment> }
        </FormLabel>
        <div className="input-wrap input-wrap-less-top-padding">
          {member.hideProfileHint && textFieldsMember1}
          {!member.hideProfileHint && 
            <div className="textInputWrapperMemberProfile">
              <h4>If there are no changes, please leave blank.</h4>
              {textFieldsMember1}
            </div>
          }
          <div className="infoText" style={{ marginTop: '3.5em', marginBottom: '0' }}>I can help with:</div>
          <FormGroup>
            {canHelpWithItems.map((item, index) => (
              <FormControlLabel key={index} control={<Checkbox checked={member.canHelpWith.includes(item)} value={item} onChange={inputChange} name="canHelpWith" />} label={item} />
            ))}
          </FormGroup>
        </div>

        { (familyMembership) &&

            <React.Fragment>
              <FormLabel style={{ fontSize: '1.1rem', fontWeight: 600, color: '#000' }} >
                {member2FirstNameCap}'s Art Profile
              </FormLabel>
              <div className="input-wrap input-wrap-less-top-padding">
                {member2.hideProfileHint2 && textFieldsMember2}
                {!member2.hideProfileHint2 && 
                  <div className="textInputWrapperMemberProfile">
                    <h4>If there are no changes, please leave blank.</h4>
                    {textFieldsMember2}
                  </div>
                }
                <div className="infoText" style={{ marginTop: '3.5em', marginBottom: '0' }}>I can help with:</div>
                <FormGroup>
                  {canHelpWithItems.map((item, index) => (
                    <FormControlLabel key={index} control={<Checkbox checked={member2.canHelpWith2.includes(item)} value={item} onChange={inputChange2} name="canHelpWith2" />} label={item} />
                  ))}
                </FormGroup>
              </div>
            </React.Fragment>
          }

          <div style={{display: 'flex', justifyContent: 'center'}}>
            <Button onClick={hideProfile} variant="contained" color="primary" style={{borderRadius: '4px', marginTop: '1.5em'}}>
              &lt;&lt; Back
            </Button>

            <Button type="submit" variant="contained" color="primary" style={{borderRadius: '4px', marginTop: '1.5em', marginLeft: '40px'}}>
              Order Summary &gt;&gt;
            </Button>
          </div>
      </div>
    </form>
  );
}

export default MemberProfile;