import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';

class BannerText extends Component {
  state = {
    isHovered: false,
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  // scrollWithOffset(el){
  //   setTimeout(function () {
  //     const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
  //     const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0); // Viewport width
  //     const yOffset = (vw < 550) ? -100 : -153;   // Set yOffset depending on vw, main-class has a shift among severl at this point.
  //     window.scrollTo({ top: yCoordinate + yOffset});
  //   }, 320);
  // }

  handleHover = () => {
    this.setState((prevState) => ({
      isHovered: !prevState.isHovered,
    }));
  };

  render() {
    const hover = this.state.isHovered ? 'hover' : '';
    const { link, mainText, arrowText, showBanner } = this.props.bannerText;
    let hideArrowText = 'hide';
    let centerMainText = 'bannerText-main';

    if (arrowText) {
      hideArrowText = '';
      centerMainText = 'aboutCart-p';
    }

    if (showBanner) {
      // Display component only, if flag is true
      return (
        <div className="bannerText">
          {/* If link starts with '/uploads', render component with a-tag wrapped around it. */}
          {link.substring(0, 8) !== '/uploads' && (
            <a
              href={link}
              target="_blank"
              rel="noreferrer"
              onMouseEnter={this.handleHover}
              onMouseLeave={this.handleHover}
            >
              {/* <HashLink to="/events#studioTourMap" scroll={el => this.scrollWithOffset(el)} onMouseEnter={this.handleHover} onMouseLeave={this.handleHover}> */}
              <div className="margin-container-sm">
                <div
                  className="bottomMargin-container"
                  style={{ marginBottom: '5rem' }}
                >
                  <div className="aboutCart-row">
                    <div className={centerMainText}>
                      <h1 className="h1-landing">{mainText}</h1>
                    </div>
                    <div className={`landing-sideInfo ${hideArrowText}`}>
                      {/* <span className={`material-icons-round arrow-landing ${hover}`}>east</span><span className="infoText infoText-landing">UPCOMING</span> */}
                      <span
                        className={`material-icons-round arrow-landing ${hover}`}
                      >
                        east
                      </span>
                      <span className="infoText infoText-landing">
                        {arrowText}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </a>
          )}
          {link && link.substring(0, 8) === '/uploads' && (
            <Link
              to={link}
              onMouseEnter={this.handleHover}
              onMouseLeave={this.handleHover}
            >
              {/* <HashLink to="/events#studioTourMap" scroll={el => this.scrollWithOffset(el)} onMouseEnter={this.handleHover} onMouseLeave={this.handleHover}> */}
              <div className="margin-container-sm">
                <div
                  className="bottomMargin-container"
                  style={{ marginBottom: '5rem' }}
                >
                  <div className="aboutCart-row">
                    <div className={centerMainText}>
                      <h1 className="h1-landing">{mainText}</h1>
                    </div>
                    <div className={`landing-sideInfo ${hideArrowText}`}>
                      {/* <span className={`material-icons-round arrow-landing ${hover}`}>east</span><span className="infoText infoText-landing">UPCOMING</span> */}
                      <span
                        className={`material-icons-round arrow-landing ${hover}`}
                      >
                        east
                      </span>
                      <span className="infoText infoText-landing">
                        {arrowText}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          )}
          {!link && (
            <div className="margin-container-sm">
              <div
                className="bottomMargin-container"
                style={{ marginBottom: '5rem' }}
              >
                <div className="aboutCart-row">
                  <div className={centerMainText}>
                    <h1 className="h1-landing">{mainText}</h1>
                  </div>
                  <div className={`landing-sideInfo ${hideArrowText}`}>
                    {/* <span className={`material-icons-round arrow-landing ${hover}`}>east</span><span className="infoText infoText-landing">UPCOMING</span> */}
                    <span
                      className={`material-icons-round arrow-landing ${hover}`}
                    >
                      east
                    </span>
                    <span className="infoText infoText-landing">
                      {arrowText}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      );
    }

    return null;
  }
}

export default BannerText;
