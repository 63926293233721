import styled from 'styled-components';

export const StyledMain = styled.main`

  /* Transition time has to match: StyledTopBarDiv and StyledNavMobile. Also 2 places connected to this timeout in App.js  */
  ${({mainTransition}) => mainTransition && `
    transition: margin-top 0.5s;
  `};


  ${({topBarClosed, mainTopMargin}) => (!topBarClosed) && `
    margin-top: calc(71px + ${mainTopMargin}px);
  `}
  ${({topBarClosed}) => (topBarClosed) && `
    margin-top: 71px;
  `}


  ${({sm}) => (sm === '-sm') && `
    margin-top: 0;
    transition: margin-top 0s;
  `}

  @media (min-width: 340px) {
    ${({topBarClosed, mainTopMargin}) => (!topBarClosed) && `
      margin-top: calc(100px + ${mainTopMargin}px);
    `}
    ${({topBarClosed}) => (topBarClosed) && `
      margin-top: 100px;
    `}


    ${({sm}) => (sm === '-sm') && `
      margin-top: 0;
      transition: margin-top 0s;
    `}
  }

  @media (min-width: 550px) {
    ${({topBarClosed, mainTopMargin}) => (!topBarClosed) && `
      margin-top: calc(128px + ${mainTopMargin}px);
    `}
    ${({topBarClosed}) => (topBarClosed) && `
      margin-top: 128px;
    `}

    ${({sm}) => (sm === '-sm') && `
      margin-top: 0;
      transition: margin-top 0s;
  `}
  }

  @media (min-width: 1300px) {
    ${({topBarClosed, mainTopMargin}) => (!topBarClosed) && `
      margin-top: calc(153px + ${mainTopMargin}px);
    `}
    ${({topBarClosed}) => (topBarClosed) && `
      margin-top: 153px;
    `}

    ${({sm}) => (sm === '-sm') && `
      margin-top: 0;
      transition: margin-top 0s;
  `}
  }
`;