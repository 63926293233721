import React, { Component } from 'react';
import { StyledImageSkeletonOverlay } from './styles/imageSkeletonOverlay.styled';
import ImgNextGen from './imgNextGen';

class HeroImage extends Component {
  state = {
    isRendered: false
  };

  render() {
    const { image } = this.props;

    const loaded = () => {
      this.setState({ isRendered: true });
    };
    const opacity = (this.state.isRendered) ? ' ' : 'hideFade'; 

    return ( 
      <div className="heroImage">
        <div className="heroImage-container">
          <div className="heroImage-wrap">
            <StyledImageSkeletonOverlay/>
            <ImgNextGen 
                srcWebp={image.srcWebp}
                fallback={image.src}
                alt={image.alt}
                onLoad={loaded}
                className={`${opacity}`}
              />
            {/* <img src={image.src} alt={image.alt} onLoad={loaded} className={`${opacity}`} /> */}
          </div>
        </div>
      </div>
    );
  }
}
 
export default HeroImage;