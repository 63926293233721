import React, { Component } from 'react';
import Paragraph from './paragraph';
import ParagraphWithSlashes from './paragraphWithSlashes';
import { StyledImageSkeletonOverlay } from './styles/imageSkeletonOverlay.styled';
import ImgNextGen from './imgNextGen';

class FeaturedCartMarkup extends Component {
  state = {
    isRendered: false
  };

  render() {
    const { heading, image, sideText, subHeading, text } = this.props.cart;
    const { link, isHovered } = this.props;

    const showHideSideCol = (sideText === '') ? 'featuredCart-grid-noSideCol' : '';

    const h1Classes = isHovered ? 'h1-cartLarge hover' : 'h1-cartLarge';

    // for skeleton loading
    const loaded = () => {
      this.setState({ isRendered: true });
    };
    const opacity = (this.state.isRendered) ? ' ' : 'hideFade'; 

    return (
      <React.Fragment>
        <div className="featuredCart-imgContainer">
          <div className="featuredCart-imgWrap">
            <StyledImageSkeletonOverlay />
            <ImgNextGen 
              srcWebp={image.srcWebp}
              fallback={image.src}
              alt={image.alt}
              onLoad={loaded}
              className={`${opacity}`}
            />
            {/* <img src={image.src} alt={image.alt} /> */}
          </div>
        </div>
        <div className={`featuredCart-grid ${showHideSideCol}`}>
          <div className="featuredCart-gridItem"><h3 className="h3-cartLarge">{subHeading}</h3></div>
          <div className="featuredCart-gridItem featuredCart-gridItem-mainHeading"><h1 className={h1Classes}>{heading}</h1></div>
          <div className="featuredCart-gridItem featuredCart-gridItem-info infoText">
            <ParagraphWithSlashes text={sideText} />
          </div> 
          <div className="featuredCart-gridItem featuredCart-gridItem-para">
            <Paragraph text={text} />
          </div>

          {
            link &&
            
              <div className="featuredCart-gridItem featuredCart-gridItem-link infoText">Learn more &gt;&gt;</div>
          }

        </div>
      </React.Fragment>
    );
  }
}

export default FeaturedCartMarkup;