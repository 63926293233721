import React, { Component } from 'react';
import ArtAroundTownInfo from './artAroundTownInfo';

class Venue extends Component {

  render() {
    const { venue } = this.props;
    const { image } = venue;

    return ( 
      <div className="infoCartImgRight">
        <div className="infoCartImgRight-row">
          <div className="infoCartImgRight-img">
            <div className="infoCartImgRight-img-container">
              <div className="infoCartImgRight-img-wrap">
                <img src={image.src} alt={image.alt} />
              </div>
            </div>
          </div>
          <div className="infoCartImgRight-info">
            <ArtAroundTownInfo venue={venue}/>
          </div>
        </div>
      </div>
    );
  }
}
 
export default Venue;