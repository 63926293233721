import React, { Component } from 'react';

class NavBurgerLines extends Component {
  render() {
    const activeClass = this.props.activeClass; // activeClass can be either 'burgerActive' or ' '
    
    return (
      <div onClick={this.props.handleTopBarClose} className={`navBurger ${activeClass}`} style={{cursor: 'pointer'}}>
        <div className="navBurger-line"></div>
        <div className="navBurger-line"></div>
        <div className="navBurger-line"></div>
      </div>
    );
  }
}

export default NavBurgerLines;