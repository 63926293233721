import axios from "axios";
import { toast } from 'react-toastify';

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

// handle unexpected errors globally
axios.interceptors.response.use(null, error => {
  const expectedError =
    error.response &&
    error.response.status >= 400 &&
    error.response.status < 500;

  if (!expectedError) {
    // TODO: consider logging error w/ Raven
    console.log('Logging the error', error);  // TODO: comment out?
    toast.error('An unexpected error occurred. Please contact member services if the error persists.',{
      onClose: () => window.location = '/' // TODO: not sure if best option
    });
  }

  return Promise.reject(error); // on rejection, hand over control to catch block
});

function setJwt(jwt) { // // Fixes bi-directional dependency with authService.
  axios.defaults.headers.common['x-auth-token'] = jwt;
}

// export default {
//   get: axios.get,
//   post: axios.post,
//   put: axios.put,
//   patch: axios.patch,
//   delete: axios.delete,
//   setJwt
// };

const http = {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  patch: axios.patch,
  delete: axios.delete,
  setJwt
};

export default http;