import http from './httpService';

let apiEndpoint = '/members';

// TODO: when adding 'membershipActivationDate', make sure its formated correct so there won't show a 1 day difference in db
// TODO: sufficient to add 'http.post(apiEndpoint, member)' .. like in patch route ??
// export function register(member) {
//   return http.post(apiEndpoint, {
//     firstName: member.firstName,
//     lastName: member.lastName,
//     street: member.street,
//     city: member.city,
//     state: member.state,
//     zip: member.zip,
//     phone: member.phone,
//     email: member.email,
//     membershipActive: member.membershipActive, 
//     membershipLevel: member.membershipLevel, 
//     membershipActivationDate: member.membershipActivationDate
//   });
// }

export function getMembers() {
  return http.get(`${apiEndpoint}`);
}

export function getMemberByEmail(member) {
  if (member.email2) return http.get(`${apiEndpoint}/${member.email2}`);
  return http.get(`${apiEndpoint}/${member.email}`);
}

export function getMemberByNameAndEmail(member) {
  if (member.email2) return http.get(`${apiEndpoint}/${member.firstName2}/${member.lastName2}/${member.email2}`);
  return http.get(`${apiEndpoint}/${member.firstName}/${member.lastName}/${member.email}`);
}

// TODO: when adding 'membershipActivationDate', make sure its formated correct so there won't show a 1 day difference in db
// export function updateMemberStatus(member) {
//   http.patch(`${apiEndpoint}/${member.email}`, member);
// }


// Note: 
// cleaner to export default obj, then we can avoid e.g. in membership component, importing via "* as memberService from ...", 
// instead simply via "import memberService from ..." then use via "memberService.getMemberByEmail"
// e.g.:   
// export default {
//   getMemberByEmail,
//   getMemberByNameAndEmail
// }