import React, { Component } from 'react';
import Crossfade from './crossfade';
import GalleryAbout from './galleryAbout';
import LocationMap from './locationMap';
// import CurrentShow from "./currentShow"; // TODO: uncomment when rendering CurrentShow
import { getCrossfadeImagesByName } from './../services/crossfadeService';

class Gallery extends Component {
  state = {
    crossfadeImages: []
  };

  componentDidMount() {
    window.scrollTo(0, 0);

    const setStateCrossfadeImages = async () => {
      const result = await getCrossfadeImagesByName('gallery');
      let imgArr = result.data[0].images;

      // Sort images by postion property.
      imgArr.sort((a,b) => (a.position > b.position) ? 1 : ((b.position > a.position) ? -1 : 0))

      this.setState({ crossfadeImages: imgArr });
    };
    setStateCrossfadeImages();
  }

  render() { 

    const { crossfadeImages } = this.state;
    // const { user, galleryData } = this.props; // TODO: uncomment when rendering CurrentShow
    const { galleryData } = this.props;


    // Return empty div if data from db not present.
    if (typeof galleryData === 'string') return <div></div>
    
    return ( 
      <React.Fragment>
        <div className="margin-container">
          <Crossfade crossfadeImages={crossfadeImages} />
        </div>
        <div className="margin-container-sm">
          <GalleryAbout galleryData={galleryData}/>
        </div>
        {/* <div className="margin-container-sm">
          <CurrentShow user={user} currentShow={galleryData.show} />
        </div> */}
        <div className="margin-container-sm">
          <LocationMap />
        </div>
      </React.Fragment>
    );
  }
}
 
export default Gallery;