import React, { Component } from 'react';
import HeroImage from './heroImage';
import ContactCart from './contactCart';
import LocationMap from './locationMap';


class Contact extends Component {

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const { galleryData, contactData } = this.props;
    const { image } = contactData;

    // Return empty div if data from db not present.   // TODO: Create empty state model of data in app.js, so this won't be needed with empty.
    if (typeof galleryData === 'string') return <div></div>

    return ( 
      <React.Fragment>
        <div className="contact">
          <div className="margin-container">
            <HeroImage image={image} />
          </div>
          <div className="margin-container-sm">
            <ContactCart galleryData={galleryData} contactData={contactData} />
          </div>
          <div className="margin-container-sm">
            <LocationMap />
          </div>
        </div>
      </React.Fragment>
    );
  }
}
 
export default Contact;