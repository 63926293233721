import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class ScholorshipInfo extends Component {
  render() {
    const { paragraph, subheading, recipients } = this.props.scholorships;
    
    return (
      <div className="scholorshipInfo"> 
        <p>{paragraph}</p>
        <h3>{subheading}</h3>
        <ul>
          {recipients.map(recipient => (            
            <li key={recipient._id}>

              {/* TODO: Reg links, put specific instruction to Admin. To properly write link e.g. either none; start w '/' or start w 'https://' */}
              {!recipient.link && <React.Fragment><span>{recipient.name}, </span><span>{recipient.year}, </span><span>{recipient.school}</span></React.Fragment>}
              {recipient.link.charAt(0) === '/' && <React.Fragment><Link to={recipient.link}>{recipient.name}</Link>, <span>{recipient.year}, </span><span>{recipient.school}</span></React.Fragment>}
              {recipient.link.charAt(0) === 'h' && <React.Fragment><a href={recipient.link} target="_blank" rel="noreferrer">{recipient.name}</a>, <span>{recipient.year}, </span><span>{recipient.school}</span></React.Fragment>}
            </li>
          ))}
        </ul>
      </div>
    );
  }
}
 
export default ScholorshipInfo;