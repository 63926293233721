import React, { Component } from 'react';
import Joi from 'joi-browser';
import { FormLabel, TextField, Button } from '@material-ui/core';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import register from '../../services/userService';

class RegisterForm extends Component {
  state = {
    user: {
      username: '',
      password: ''
    },
    errors: {}
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  theme = createMuiTheme ({
    typography: {
      fontFamily: "'Nunito', sans-serif",
      fontSize: 11.5
    },
    palette: {
      primary: {
        main: '#00CCFF'
      },
      secondary: {
        main: '#00CCFF'
      }
    }
  });

  schema = {
    username: Joi.string().min(5).trim().required().label("Username"),
    password: Joi.string().min(7).trim().required().label("Password")
  };

  validate = () => {
    const result = Joi.validate(this.state.user, this.schema, { abortEarly: false });
    if (!result.error) return null;

    const errors = {};
 
    for (let item of result.error.details) errors[item.path[0]] = item.message;

    return errors;
  };

  handleChange = ({ currentTarget: input }) => {
    const user = { ...this.state.user };
    user[input.name] = input.value;

    this.setState({ user });
  };

  handleSubmit = async (e) => {
    e.preventDefault();

    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) return;
    
    try {
      await register(this.state.user);
      window.location = '/';
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        const errors = { ...this.state.errors };
        errors.username = ex.response.data;
        this.setState({ errors });
      }
    }

  }

  render() {
    const { user, errors } = this.state;

    return (
      <ThemeProvider theme={this.theme}> 
        <section className="login">
          <div className="margin-container-narrow login-container">

            <form className="login-form" onSubmit={this.handleSubmit}>
              <div className="login-form-col">
                <FormLabel style={{ fontSize: '1.1rem', fontWeight: 600, color: '#000' }} >
                  Docent Registration
                </FormLabel>
                <div className="input-wrap">
                  <TextField error={!!errors.username} helperText={errors.username} value={user.username} onChange={this.handleChange} id="username" name="username" type="text" label="Username" style={{ marginRight: '2em' }} /><br/>
                  <TextField error={!!errors.password} helperText={errors.password} value={user.password} onChange={this.handleChange} id="password" name="password" type="password" label="Password" style={{ marginTop: '1.5em' }} />
                </div>
                <Button type="submit" variant="contained" color="primary" style={{borderRadius: '4px', marginTop: '1.5em'}}>
                  Register
                </Button>
              </div>
            </form>

          </div>
        </section>
      </ThemeProvider>
    );
  }
}

export default RegisterForm;