import React, { Component } from 'react';
import ArtistIntro from './artistIntro';
import ArtistMain from './artistMain';
import { getArtistByName } from '../services/artistService';
import { Redirect } from 'react-router-dom';
import { _ToSpace } from '../utils/helperFuncs';

class Artist extends Component {
  state = { artist: {} };

  // Version1) IF USER NAVIGATES HERE VIA LINK ON ARTISTS PAGE:  this.props.location.state.artist (we save a trip to db that way)
  // Version2) IF USER TYPES IN ARTIST NAME DIRECTLY: get data from db via name-param

  propsUndefined = () => {
    if (typeof this.props.location.state === 'undefined') return true;
  };

  componentDidMount() {
    window.scrollTo(0, 0);

    // for Version2
    if (this.propsUndefined()) {
      const firstName = _ToSpace(this.props.match.params.firstName);
      const lastName = _ToSpace(this.props.match.params.lastName);

      const getArtistsSetState = async () => {
        try {
          const result = await getArtistByName(firstName, lastName);
          const artist = result.data;
          this.setState({ artist });
        } catch (ex) {
          const artist = { notFound: true };
          this.setState({ artist });
        }
      };
      getArtistsSetState();
    }
  }

  render() {
    // START: decide Version1 or Version2
    const { artist } = this.propsUndefined()
      ? this.state
      : this.props.location.state;

    // for Version2
    // render nothing if no data from server (conditional rendering)
    if (
      artist &&
      Object.keys(artist).length === 0 &&
      artist.constructor === Object
    )
      return null; // if artist obj in state is empty

    // for Version2
    if (artist.notFound) return <Redirect to="/not-found" />;

    if (artist.hidden) return <Redirect to="/not-found" />;

    return (
      <React.Fragment>
        <ArtistIntro artist={artist} />
        <ArtistMain artist={artist} />
      </React.Fragment>
    );
  }
}

export default Artist;
