import http from './httpService';

let apiEndpoint = '/artists';

export function getArtists() {
  return http.get(`${apiEndpoint}`);
}

export function getArtistByName(firstName, lastName) {
  return http.get(`${apiEndpoint}/${firstName}-${lastName}`);
}

