import React, { Component } from 'react';
import Paragraph from './paragraph';

class ArtAroundTownInfo extends Component {
  render() {

    const { heading, paragraph, address, phone, email, hours:weekHours} = this.props.venue;
    const { googleMapsLink, street, city, state, zip } = address;

    return ( 
      <div className="artAroundTownInfo">
        <h3>{heading}</h3>
        <Paragraph text={paragraph} />
        
        <div className="artAroundTownInfo-row">
          <div className="artAroundTownInfo-col">
            <address className="infoText">
              {googleMapsLink &&
                <a href={googleMapsLink} target="_blank" rel="noreferrer">
                  {street} <br/>
                  {city} <br/>
                  {state} {zip} <br/>
                </a>
              }
              {!googleMapsLink &&
                  <React.Fragment>
                    {street} <br/>
                    {city} <br/>
                    {state} {zip} <br/>
                  </React.Fragment>
              }

            </address>
            <div className="artAroundTownInfo-phoneEmail infoText">
              {/* TODO: check if phone works w dots */}
              <a href={`tel:+1-${phone}`}>{phone}</a><br/>
              <a href={`mailto:${email}`}>{email}</a>
            </div>
          </div>
          <div className="artAroundTownInfo-col">
            <div className="artAroundTownInfo-hours">
              {weekHours.map(dayHours => (
                <div key={dayHours._id}>
                  {dayHours.day} | {dayHours.open} to {dayHours.close}
                </div>
              ))}
            </div>
          </div>
        </div>

      </div>
    );
  }
}
 
export default ArtAroundTownInfo;