import React, { Component } from 'react';
// import Paragraph from './paragraph';
// import StudioTourSideInfo from './studioTourSideInfo';

class StudioTourAbout extends Component {
  render() {
    // const { paragraphs, additionalInfo } = this.props.studioTour;
    const { additionalInfo } = this.props.studioTour;

    return (
      <div className="bottomMargin-container">
        {/* Render heading of 1st para only. */}
        {/* {paragraphs[0] && <h2>{paragraphs[0].heading}</h2>} */}
        <h2>17th Annual Artist Studio Tour – 2024</h2>

        <div className="aboutCart-row">
          <div className="aboutCart-p">
            <p>
              2024 marked the 17th anniversary of our extraordinary self-guided
              Artist Studio Tour that is held every year on the last weekend of
              July and the first weekend in August. There are multiple studios
              offering a unique opportunity to meet with and buy directly from
              the artists.
            </p>
            <p>
              The Artist Studio Tour includes a wide range of photographers,
              woodworkers, jewelers, painters, printmakers, and ceramicists.
            </p>
            <p>
              {/* Be sure to check out this years{' '}
              <a
                href="/assets/studioTourArtists2023.jpg"
                target="_blank"
                rel="noreferrer"
              >
                artists line up
              </a>
              .  */}
              We look forward to seeing you at the Tahoe Art League Gallery:
              3062 Lake Tahoe Boulevard in beautiful South Lake Tahoe.
            </p>
          </div>
          {/* <StudioTourSideInfo additionalInfo={additionalInfo} /> */}
        </div>
      </div>
    );
  }
}

export default StudioTourAbout;
