import http from './httpService';

let apiEndpoint = '/unapprovedMembers';

export function addFormData(member, orderId) {
  if (member.email2) {
    return http.post(apiEndpoint, {
      firstName: member.firstName2,
      lastName: member.lastName2,
      street: member.street2,
      city: member.city2,
      state: member.state2,
      zip: member.zip2,
      phone: member.phone2,
      email: member.email2,
      membershipLevel: member.membershipLevel2,
      website: member.website2,
      artMedium: member.artMedium2,
      educationExperience: member.educationExperience2,
      canHelpWith: member.canHelpWith2,
      newMember: member.newMember2,
      orderId: orderId
    });
  }

  return http.post(apiEndpoint, {
    firstName: member.firstName,
    lastName: member.lastName,
    street: member.street,
    city: member.city,
    state: member.state,
    zip: member.zip,
    phone: member.phone,
    email: member.email,
    membershipLevel: member.membershipLevel,
    website: member.website,
    artMedium: member.artMedium,
    educationExperience: member.educationExperience,
    canHelpWith: member.canHelpWith,
    newMember: member.newMember,
    orderId: orderId
  });
}
