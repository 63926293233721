import React from 'react';

function OrderConfirmationText({memberInfo}) {
  if (typeof memberInfo === 'undefined') return null;

  const { member, member2 } = memberInfo;

  const name = (member.membershipLevel === 'family') ? `${member.firstName} and ${member2.firstName2}` : member.firstName;
  const membersTxt = (member.membershipLevel === 'family') ? 'members' : 'a member';
  const fillerWord = ((member.newMember === true && member2.newMember2 === false) || // 1st member new, 2nd member returning
                      (member.newMember === false && member2.newMember2 === true)) ? // 1st member returning, 2nd member new
                      'both' : '';

  let firstSentence = <h2>Welcome {name}, you are now {fillerWord} {membersTxt} of the Tahoe Art League!</h2>;
  let midSentence = <h3>Our membership department looks forward helping you get involved.</h3>;
  let lastSentence = <h3>We’re really excited to have you join us at TAL.</h3>;
  let welcomeOrRenewal = 'welcome';

  if ((member.membershipLevel !== 'family' && member.newMember === false) ||   // one returning member
      (member.newMember === false && member2.newMember2 === false)) {  // two returning members
        firstSentence = <h2>Thank you {name} for renewing your Tahoe Art League membership.</h2>;
        midSentence = '';
        lastSentence = <h3>Looking forward to another great year with you!</h3>;
        welcomeOrRenewal = 'renewal';
  }


  return (
    <React.Fragment>
      {firstSentence}
      <h3>To make sure you receive your {welcomeOrRenewal} email, please check your <span style={{textDecoration: 'underline'}}>spam folder</span>.</h3>
      {midSentence}
      <h3>If you have any questions, feel free to email Claudia or Mimi at <a href="mailto:info@talart.org">info@talart.org</a></h3>
      {lastSentence}
  </React.Fragment>
  );
}

export default OrderConfirmationText;