import React, { Component } from 'react';
import TalAbout from './talAbout';
import Scholorship from './scholorship';
import Board from './board';
import ImageSlider from './imageSlider';
import { getAboutData } from '../services/aboutService';

class About extends Component {
  state = {
    aboutTal: {
      heading: '',
      paragraph: '',
      missionStatement: '',
    },
    scholorships: {
      heading: '',
      image: {
        src: '',
        alt: '',
      },
      paragraph: '',
      subheading: '',
      recipients: [],
    },
    board: {
      heading: '',
      members: [],
    },
  };

  componentDidMount() {
    window.scrollTo(0, 0);

    const getDataThenSetState = async () => {
      const result = await getAboutData();
      const aboutData = result.data;

      const aboutTal = {
        heading: aboutData.heading,
        paragraph: aboutData.paragraph,
        missionStatement: aboutData.missionStatement,
      };
      const scholorships = aboutData.scholorships;
      const board = aboutData.board;

      this.setState({ aboutTal, scholorships, board });
    };
    getDataThenSetState();
  }

  render() {
    const { aboutTal, scholorships, board } = this.state;

    return (
      <section className="topMargin-container">
        <div className="margin-container-sm">
          <TalAbout aboutTal={aboutTal} />
        </div>
        <div className="margin-container-sm">
          <Scholorship scholorships={scholorships} />
        </div>
        <div className="margin-container-sm">
          <ImageSlider />
        </div>
        <div className="margin-container-sm">
          <Board board={board} />
        </div>
      </section>
    );
  }
}

export default About;
