import React, { Component } from 'react';
import Crossfade from './crossfade';
import Venue from './venue';
import { getCrossfadeImagesByName } from '../services/crossfadeService';
import { getArtAroundTownData } from '../services/artAroundTownService';
import Paragraph from './paragraph';

class ArtAroundTown extends Component {
  state = {
    crossfadeImages: [],
    data: {
      heading: '',
      paragarph: '',
      subheading: '',
      venues: []
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);


    const getDataThenSetState = async () => {
      const result1 = await getCrossfadeImagesByName('art-around-town');
      let imgArr = result1.data[0].images;
      // Sort images by postion property.
      imgArr.sort((a,b) => (a.position > b.position) ? 1 : ((b.position > a.position) ? -1 : 0))


      const result2 = await getArtAroundTownData();
      const data = result2.data;

      this.setState({ 
        data: data,
        crossfadeImages: imgArr 
      });

    };
    getDataThenSetState();

  }

  render() { 
    const { crossfadeImages, data } = this.state;
    const { heading, paragraph, subheading, venues } = data;


    return ( 
      <section className="artAroundTown">
        <div className="margin-container">
          <Crossfade crossfadeImages={crossfadeImages} />
        </div>
        <div className="margin-container-sm">
          <h1>{heading}</h1>
          <Paragraph text={paragraph} />
          <h2>{subheading}</h2>
          {venues.map(venue => (
            <Venue venue={venue} key={venue._id} />
          ))}
        </div>
      </section>
    );
  }
}
 
export default ArtAroundTown;