import React, { Component } from 'react';
import GallerySideInfo from './gallerySideInfo';
import Paragraph from './paragraph';

class TalAbout extends Component {

  render() {
    const { aboutTal } = this.props; 

    // Renaming, because we use GallerySideInfo-component to render mission statement.
    const { missionStatement: additionalInfo } = aboutTal;
    const galleryData = { additionalInfo };

    return (
      <div className="bottomMargin-container">
        <h2>{aboutTal.heading}</h2>
        <div className="aboutCart-row">
          <div className="aboutCart-p">   {/* Was p before. */}
            <Paragraph text={aboutTal.paragraph} />
          </div>
          <GallerySideInfo galleryData={galleryData} />
        </div>
      </div>
    );
  }
}
 
export default TalAbout;