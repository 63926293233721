import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
// import DialogTitle from '@material-ui/core/DialogTitle';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Joi from 'joi-browser';

export default function SubscribeDialogBox() {
  const [open, setOpen] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  let [user, setUser] = useState(
    {
      'FNAME': '',
      'LNAME': '',
      'EMAIL': ''
    });
  const [errors, setErrors] = useState({});
  const [showSubscribe, setShowSubscribe] = useState(true);
  const [subscribeActive, setSubscribeActive] = useState(true);

  const handleClickOpen = () => {
    subscribeActive ? setOpen(true) : setOpen(false);
  }
  const handleClose = () => {
    setOpen(false);
    setTimeout(function () {  // To prevent briefly showing form when closing dialog box.
      setShowSubscribe(true);
    }, 300);
  }

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  const hover = isHovered ? 'hover' : '';

  const theme = createMuiTheme ({
    typography: {
      fontFamily: "'Nunito', sans-serif",
      fontSize: 11.5
    },
    palette: {
      primary: {
        main: '#00CCFF'
      },
      secondary: {
        main: '#00CCFF'
      }
    }
  });

  const schema = {
    EMAIL: Joi.string().email().required().lowercase().trim().regex(/[.]([a-zA-Z]{2,})/).label('EMAIL'), // require at least 2chars after period
    FNAME: Joi.string().trim().allow(''),
    LNAME: Joi.string().trim().allow('')
  };

  const validate = () => {
    const result = Joi.validate(user, schema, { abortEarly: false });

    if (!result.error) return null;

    const errors = {};
 
    for (let item of result.error.details) {
      errors[item.path[0]] = item.message;

      // custom error message for EMAIL
      if (item.context.key === 'EMAIL') errors[item.path[0]] = 'Please enter a valid email address.';

    }
    return errors;
  };

  const handleChange = ({ currentTarget: input }) => {
    user = { ...user };
    user[input.name] = input.value;
    setUser(user);
  };

  const handleSubmit = async (e) => {
    const errors = validate();
    
    setErrors(errors || {});

    if (errors) {
      e.preventDefault();
      return;
    }
  
    setTimeout( function ()  {
      setShowSubscribe(false);
      setSubscribeActive(false);

      // TODO: correct way??
      user = { ...user };
      user.FNAME = '';
      user.LNAME = '';
      user.EMAIL = '';
      setUser(user);

    }, 100);

    setTimeout( function () {
      handleClose();
    }, 3000);

  }

  return (
    <ThemeProvider theme={theme}>

      <div className="subscribe" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} onClick={handleClickOpen}>
        <div className="subscribe-text">Subscribe for Newsletter Updates</div>
        <div className="subscribe-arrow-wrap"><span className={`material-icons-round subscribe-arrow ${hover}`}>east</span></div>
      </div>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        { showSubscribe &&
              <form action="//talart.us14.list-manage.com/subscribe/post?u=fa771e291f340c9a81684403d&id=a36630ea02" onSubmit={handleSubmit} method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="myIframe" noValidate="novalidate">
                <div id="mc_embed_signup_scroll">
                {/* <DialogTitle id="form-dialog-title">Subscribe</DialogTitle> */}
                <DialogContent style={{ padding: "1.5em 1.5em .7em"}}>
                  <DialogContentText style={{fontSize: "1rem"}}>
                    Please complete this form to SUBSCRIBE to the latest TAL happenings.
                    They include classes, events, meetings, demonstrations and more.
                  </DialogContentText>
                  <TextField error={!!errors.EMAIL} helperText={errors.EMAIL} value={user.EMAIL} onChange={handleChange} autoFocus margin="dense" name="EMAIL" label="Email" type="email" fullWidth/>
                  <TextField error={!!errors.FNAME} helperText={errors.FNAME} value={user.FNAME} onChange={handleChange} margin="dense" name="FNAME" label="First Name" type="text" fullWidth/>
                  <TextField error={!!errors.LNAME} helperText={errors.LNAME} value={user.LNAME} onChange={handleChange} margin="dense" name="LNAME" label="Last Name" type="text" fullWidth/>
                  {/* real people should not fill this in and expect good things - do not remove this or risk form bot signups */}
                  <div style={{position: "absolute", left: "-5000px"}}>
                    <input type="text" name="b_fa771e291f340c9a81684403d_a36630ea02" tabIndex="-1" value="" onChange={handleChange} />
                  </div>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose} color="primary">
                    Cancel
                  </Button>
                  <Button type="submit" color="primary">
                    Subscribe
                  </Button>
                </DialogActions>
                </div>
                <iframe style={{display: "none"}} name="myIframe" title="myIframe"></iframe>
              </form>
        }

        { (!showSubscribe) &&
              <div className="subscribe-thanks" onClick={handleClose} >
                <div className="subscribe-close-wrap">
                  <span className={`material-icons-round subscribe-close`}>close</span>
                </div>
                <div className="subscribe-thanks-msg" style={{textAlign: "center"}}>
                  <h3>Thank you for subscribing!</h3>
                </div>
              </div>
        }

      </Dialog>
      
    </ThemeProvider>
  );
}
