import React from 'react';
import { SRLWrapper } from 'simple-react-lightbox';

function ImageSlider(props) {
  return (
    <SRLWrapper>
      <div className="mural-section">
        <h2>Murals</h2>
        <p>
          The Tahoe Art League facilitates and funds murals in South Lake Tahoe.
        </p>

        <div className="media-scroller snaps-inline">
          <div className="media-element">
            <img
              src="https://talart.org/uploads/about/murals/mural-4-2024.jpg"
              alt=""
            />
            <p className="smallText">Ryno Art</p>
          </div>
          <div className="media-element">
            <img
              src="https://talart.org/uploads/about/murals/mural-1-2024.jpeg"
              alt=""
            />
            <p className="smallText">Craig Newman</p>
          </div>
          <div className="media-element">
            <img
              src="https://talart.org/uploads/about/murals/mural-2-2024.jpeg"
              alt=""
            />
            <p className="smallText">Matt Kaufman</p>
          </div>
          <div className="media-element">
            <img
              src="https://talart.org/uploads/about/murals/mural-3-2024.jpeg"
              alt=""
            />
            <p className="smallText">Aspen Carillo</p>
          </div>
        </div>
      </div>
    </SRLWrapper>
  );
}

export default ImageSlider;
