import styled from 'styled-components';
import { keyframes } from 'styled-components';


const skeletonLoading = keyframes`
  0% {
    background-color: hsl(192, 13%, 78%);
  }
  
  100% {
    background-color: hsl(192, 13%, 95%);
  }
`;

export const StyledImageSkeletonOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  transition: 0.2s;
  pointer-events: none;
  animation: ${skeletonLoading} 1s linear infinite alternate;

`;