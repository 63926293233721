import http from './httpService';
import jwtDecode from 'jwt-decode';

const apiEndpoint = '/auth';
const tokenKey = 'token';

http.setJwt(getJwt()); // Fixes bi-directional dependency with httpService.

export async function login(name, password) {
  // const { data: jwt } = await http.post(apiEndpoint, { name, password });
  const res = await http.post(apiEndpoint, { name, password });
  const jwt = res.headers["x-auth-token"];
  localStorage.setItem(tokenKey, jwt);
}

export function logout() {
  localStorage.removeItem(tokenKey);
}

export function getCurrentUser() {
  try {
    const jwt = localStorage.getItem(tokenKey);
    return jwtDecode(jwt);
  } catch (ex) {
    return null
  }
}

export function getJwt() {
  return localStorage.getItem(tokenKey);
}

// export default {
//   login,
//   logout,
//   getCurrentUser,
//   getJwt
// }

const auth = {
  login,
  logout,
  getCurrentUser,
  getJwt
}

export default auth;