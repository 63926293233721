import React, { Component } from 'react';
import ArtistTileImg from './artistTileImg';
import { SRLWrapper } from 'simple-react-lightbox';
import Paragraph from './paragraph';

class ArtistMain extends Component {
  state = {};
  render() {
    const { firstName, paragraph, website, images } = this.props.artist;
    const websiteUrl = `http://${website}`;

    const filteredImages = images.filter((img) => img.hideInGrid !== true);

    return (
      <SRLWrapper>
        <section className="artistMain">
          <div className="margin-container">
            <div className="artistMain-row">
              <div className="artistMain-col1">
                <Paragraph text={paragraph} />
                {website && (
                  <div className="artistMain-link">
                    <span>{`${firstName}’s`}</span> website:
                    <br />
                    <a href={websiteUrl} target="_blank" rel="noreferrer">
                      {website}
                    </a>
                  </div>
                )}
              </div>
              <div className="artistMain-col2">
                <div className="artistMain-grid">
                  {filteredImages.map((image) => (
                    <ArtistTileImg image={image} key={image._id} />
                  ))}
                  {/* {images.map((image) => (
                    <ArtistTileImg image={image} key={image._id} />
                  ))} */}
                </div>
              </div>
            </div>
          </div>
        </section>
      </SRLWrapper>
    );
  }
}

export default ArtistMain;
