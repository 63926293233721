import React, { Component } from 'react';
import MainAndSide from './mainAndSide';
import Paragraphs from './paragraphs';
import SideInfo from './sideInfo';
import HeroImage from './heroImage';

class PleinAir extends Component {
  render() {
    const { pleinAir } = this.props;

    return (
      <section
        className="margin-container-sm bottomMargin-container"
        id="pleinair"
      >
        {pleinAir.image.src && <HeroImage image={pleinAir.image} />}{' '}
        {/* Only render image, if src is not an empty string.  */}
        <h1>{pleinAir.heading}</h1>
        <MainAndSide
          paragraphs={pleinAir.paragraphs}
          main={<Paragraphs paragraphs={pleinAir.paragraphs} />}
          side={<SideInfo additionalInfo={pleinAir.additionalInfo} />}
        />
      </section>
    );
  }
}

export default PleinAir;
