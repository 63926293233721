import React, { Component } from 'react';
import JoinUsColumn from './joinUsColumn';
import { getJoinColumnsData } from '../services/joinColumnService';

class JoinUsRow extends Component {
  state = {
    joinColumns: [
      // {
      //   heading: '',
      //   list: [],
      //   button: {
      //     link: '',
      //     lable: ''
      //   }
      // }
    ]
  }

  componentDidMount() {

    const getDataThenSetState = async () => {
      const result = await getJoinColumnsData();
      const joinColumns = result.data;

      this.setState({ joinColumns });

    };
    getDataThenSetState();

  }

  render() {
    const { joinColumns } = this.state;
    let classes = "joinUs-grid";
    if (joinColumns.length === 2) classes += " joinUs-grid-2";
    if (joinColumns.length === 3) classes += " joinUs-grid-3";

    return (
      <div className={classes}>
        {joinColumns.map(column => (
          <JoinUsColumn columnData={column} key={column._id}/>
        ))}
      </div>
    );
  }
}

export default JoinUsRow;