import React, { Component } from 'react';
// import { Link } from "react-router-dom";
import { HashLink as Link } from 'react-router-hash-link';
import FeaturedCartMarkup from './featuredCartMarkup';
import DeleteDialogBox from '../components/common/deleteDialogBox';
import { deleteFeaturedCart } from '../services/featuredCartService';

class FeaturedCart extends Component {
  state = { isHovered: false };

  handleHover = () => {
    this.setState((prevState) => ({
      isHovered: !prevState.isHovered,
    }));
  };

  render() {
    const { cart, user } = this.props;
    const { link } = cart;
    const { isHovered } = this.state;

    return (
      <div className="featuredCart">
        {/* If link starts with '/uploads', render component with a-tag wrapped around it. */}
        {link.substring(0, 8) === '/uploads' && (
          <React.Fragment>
            {/* { user ? (user.isAdmin || user.role === 'featuredCartsAdmin') && */}
            {user
              ? (user.isAdmin ||
                  user.role?.some((elem) =>
                    ['featuredCartsAdmin'].includes(elem)
                  )) && (
                  <React.Fragment>
                    <Link
                      className="admin-btn"
                      to={{
                        pathname: `/featuredcarts/${cart._id}`,
                        state: {
                          carts: this.props.carts,
                        },
                      }}
                    >
                      Edit
                    </Link>
                    <DeleteDialogBox
                      cartId={cart._id}
                      deleteFeaturedCart={deleteFeaturedCart}
                    />
                  </React.Fragment>
                )
              : null}

            <a
              href={link}
              target="_blank"
              rel="noreferrer"
              onMouseEnter={this.handleHover}
              onMouseLeave={this.handleHover}
            >
              <FeaturedCartMarkup
                cart={cart}
                link={true}
                isHovered={isHovered}
              />
            </a>
          </React.Fragment>
        )}

        {/* If link starts with '/' AND NOT with '/uploads', render component with Link-tag wrapped around it. */}
        {link.charAt(0) === '/' && link.substring(0, 8) !== '/uploads' && (
          <React.Fragment>
            {/* { user ? (user.isAdmin || user.role === 'featuredCartsAdmin') && */}
            {user
              ? (user.isAdmin ||
                  user.role?.some((elem) =>
                    ['featuredCartsAdmin'].includes(elem)
                  )) && (
                  <React.Fragment>
                    <Link
                      className="admin-btn"
                      to={{
                        pathname: `/featuredcarts/${cart._id}`,
                        state: {
                          carts: this.props.carts,
                        },
                      }}
                    >
                      Edit
                    </Link>
                    <DeleteDialogBox
                      cartId={cart._id}
                      deleteFeaturedCart={deleteFeaturedCart}
                    />
                  </React.Fragment>
                )
              : null}

            <Link
              to={link}
              onMouseEnter={this.handleHover}
              onMouseLeave={this.handleHover}
            >
              <FeaturedCartMarkup
                cart={cart}
                link={true}
                isHovered={isHovered}
              />
            </Link>
          </React.Fragment>
        )}

        {/* If link starts with 'h', render component with a-tag wrapped around it. */}
        {link.charAt(0) === 'h' && (
          <React.Fragment>
            {/* { user ? (user.isAdmin || user.role === 'featuredCartsAdmin') && */}
            {user
              ? (user.isAdmin ||
                  user.role?.some((elem) =>
                    ['featuredCartsAdmin'].includes(elem)
                  )) && (
                  <React.Fragment>
                    <Link
                      className="admin-btn"
                      to={{
                        pathname: `/featuredcarts/${cart._id}`,
                        state: {
                          carts: this.props.carts,
                        },
                      }}
                    >
                      Edit
                    </Link>
                    <DeleteDialogBox
                      cartId={cart._id}
                      deleteFeaturedCart={deleteFeaturedCart}
                    />
                  </React.Fragment>
                )
              : null}

            <a
              href={link}
              target="_blank"
              rel="noreferrer"
              onMouseEnter={this.handleHover}
              onMouseLeave={this.handleHover}
            >
              <FeaturedCartMarkup
                cart={cart}
                link={true}
                isHovered={isHovered}
              />
            </a>
          </React.Fragment>
        )}

        {/* If link empty, render component as is. */}
        {link.charAt(0) === '' && (
          <React.Fragment>
            {/* { user ? (user.isAdmin || user.role === 'featuredCartsAdmin') && */}
            {user
              ? (user.isAdmin ||
                  user.role?.some((elem) =>
                    ['featuredCartsAdmin'].includes(elem)
                  )) && (
                  <React.Fragment>
                    <Link
                      className="admin-btn"
                      to={{
                        pathname: `/featuredcarts/${cart._id}`,
                        state: {
                          carts: this.props.carts,
                        },
                      }}
                    >
                      Edit
                    </Link>
                    <DeleteDialogBox
                      cartId={cart._id}
                      deleteFeaturedCart={deleteFeaturedCart}
                    />
                  </React.Fragment>
                )
              : null}

            <FeaturedCartMarkup cart={cart} />
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default FeaturedCart;
