import React, { Component } from 'react';

class SideInfo extends Component {
  render() {
    const { additionalInfo } = this.props;

    return (
      <React.Fragment>
        {additionalInfo.map((info, index) => (
          <React.Fragment key={index}>
            <h4>{info.heading}</h4>
            <p>{info.text}</p>
          </React.Fragment>
        ))}
      </React.Fragment>
    );
  }
}

export default SideInfo;