import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
// import Box from '@material-ui/core/Box';
// import Collapse from '@material-ui/core/Collapse';
// import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
// import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
// import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
// import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import { useState, useEffect } from 'react';
import { getMembers } from './../services/memberService';

import MemberDialog from './memberDialog';

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      // borderBottom: 'unset',
      borderBottom: '',
    },
  },
});

function Row(props) {
  const { member } = props;
  // const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();

  const date = new Date(member.membershipActivationDate);
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  const formattedDate = date.toLocaleDateString('en-US', options);

  const [openDialog, setOpenDialog] = React.useState(false);
  const handleOpenDialog = () => {
    setOpenDialog(true);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <React.Fragment>
      <MemberDialog
        member={member}
        handleCloseDialog={handleCloseDialog}
        openDialog={openDialog}
      />
      <TableRow className={classes.root}>
        <TableCell>
          {/* <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton> */}
        </TableCell>
        <TableCell component="th" scope="row" onClick={handleOpenDialog}>
          {member.firstName} {member.lastName}
        </TableCell>
        <TableCell align="right">{member.email}</TableCell>
        {/* <TableCell align="right">
          {member.street}
          <br />
          {member.city}
          <br />
          {member.state}
          {member.state && <span>,</span>} {member.zip}
        </TableCell> */}
        {/* <TableCell align="right">{member.phone}</TableCell> */}
        <TableCell align="right">
          {member.membershipLevel.charAt(0).toUpperCase() +
            member.membershipLevel.slice(1)}
        </TableCell>
        <TableCell align="right">
          {member.membershipActive ? 'Active' : 'Expired'}
        </TableCell>
        {/* Capitalize first letter */}
        <TableCell align="right">{formattedDate}</TableCell>
      </TableRow>

      {/* <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                Payment
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Payer Name</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell align="right">Amount</TableCell>
                    <TableCell align="right">Time</TableCell>
                    <TableCell align="right">
                      Capture Id / Order Id / Payer Id
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      {member.payment.firstName} {member.payment.lastName}
                    </TableCell>
                    <TableCell>{member.payment.payerEmail}</TableCell>
                    <TableCell align="right">
                      {(Math.round(member.payment.amount * 100) / 100).toFixed(
                        2
                      )}
                    </TableCell>
                    <TableCell align="right">{member.payment.time}</TableCell>
                    <TableCell align="right">
                      {member.payment.captureId}
                      <br />
                      {member.payment.orderId}
                      <br />
                      {member.payment.payerId}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow> */}
    </React.Fragment>
  );
}

export default function CollapsibleTable() {
  const [members, setMembers] = useState([]);

  const [selectedCol, setSelectedCol] = useState('date');
  const [sortOrders, setSortOrders] = useState({
    name: 'asc',
    type: 'asc',
    date: 'asc',
  });

  // Get data via useEffect-hook
  useEffect(() => {
    async function gettingMembers() {
      const result = await getMembers();

      setMembers(result.data);
    }

    gettingMembers();
  }, []);

  const handleSort = (columnName) => {
    setSelectedCol(columnName);

    let newSortOrders = { ...sortOrders };
    if (columnName === selectedCol) {
      // Reverse the sort order if the same column is clicked again
      newSortOrders[columnName] =
        sortOrders[columnName] === 'asc' ? 'desc' : 'asc';
    } else {
      // If a different button is clicked, set the sort order to ascending
      newSortOrders[columnName] = 'asc';
    }
    setSortOrders(newSortOrders);

    let sortedMembers = [...members];
    switch (columnName) {
      case 'name':
        sortedMembers.sort((a, b) => a.lastName.localeCompare(b.lastName));
        break;
      case 'type':
        sortedMembers.sort((a, b) =>
          a.membershipLevel.localeCompare(b.membershipLevel)
        );
        break;
      case 'date':
        sortedMembers.sort(
          (a, b) =>
            new Date(a.membershipActivationDate) -
            new Date(b.membershipActivationDate)
        );
        break;
      default:
        break;
    }

    // Reverse the array if sort order is descending
    if (newSortOrders[columnName] === 'desc') {
      sortedMembers.reverse();
    }

    setMembers(sortedMembers);
  };

  const renderArrow = (columnName) => {
    if (selectedCol === columnName) {
      if (sortOrders[columnName] === 'asc') {
        return <span class="material-icons-round">expand_less</span>;
      } else {
        return <span class="material-icons-round">expand_more</span>;
      }
    }
    return null;
  };

  const getArrowOpacity = (columnName) => {
    return selectedCol === columnName ? '100%' : '0%';
  };

  return (
    <div className="margin-container-sm" style={{ paddingTop: '2rem' }}>
      <h1>Members</h1>
      <TableContainer
        component={Paper}
        style={{ maxHeight: 700, maxWidth: 900 }}
      >
        <Table stickyHeader aria-label="sticky collapsible table">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell
                style={{ fontWeight: 600 }}
                onClick={() => handleSort('name')}
              >
                <span style={{ opacity: getArrowOpacity('name') }}>
                  {renderArrow('name')}
                </span>
                Name
              </TableCell>
              <TableCell align="right" style={{ fontWeight: 600 }}>
                Email
              </TableCell>
              {/* <TableCell align="right" style={{ fontWeight: 600 }}>
                Address
              </TableCell> */}
              {/* <TableCell align="right" style={{ fontWeight: 600 }}>
                Phone
              </TableCell> */}
              <TableCell
                align="right"
                style={{ fontWeight: 600 }}
                onClick={() => handleSort('type')}
              >
                <span
                  class="material-icons-round"
                  style={{ opacity: getArrowOpacity('type') }}
                >
                  {renderArrow('type')}
                </span>
                Type
              </TableCell>
              <TableCell align="right" style={{ fontWeight: 600 }}>
                Status
              </TableCell>
              <TableCell
                align="right"
                style={{ fontWeight: 600 }}
                onClick={() => handleSort('date')}
              >
                <span
                  class="material-icons-round"
                  style={{ opacity: getArrowOpacity('date') }}
                >
                  {renderArrow('date')}
                </span>
                Activation Date
              </TableCell>
              {/* <TableCell align="right" style={{fontWeight: 600}}>Able to Renew</TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {members.map((member) => (
              <Row key={member.email} member={member} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div className="infoText">
        Members: {members.length} (active:{' '}
        {members.filter((member) => member.membershipActive).length} expired:{' '}
        {members.filter((member) => !member.membershipActive).length})
      </div>
    </div>
  );
}
