import React, { Component } from 'react';
import { withRouter} from 'react-router-dom'; // Needed for redirect
import http from '../services/httpService';
import * as memberService from '../services/memberService';
import * as unapprovedMemberService from '../services/unapprovedMemberService'; // TODO: Only export the one post route we need
import { toast } from 'react-toastify';
// import Membership from './membership';

class PayPalBtn extends Component {

  componentDidMount () {
    const { memberInfo, history } = this.props;
    const { member, member2 } = memberInfo;

    window.paypal.Buttons({

      // My Custom Validation
      onClick: function (data, actions) {

        const checkMember = (member) => {
          const getMember = new Promise((resolve, reject) => {
            const result = memberService.getMemberByEmail(member);
            result
              .then(() => { // Email in db
                const result = memberService.getMemberByNameAndEmail(member);
                result
                  .then((res) => { // Name & email match, return membershipStatus
                    if (!res.data[0].ableToRenew) { // Not able to renew.
                      toast.error('Your membership is active until December 31st. Renewal opens October 1st.', {
                        onClose: () => history.replace('/')
                      });
                      reject();
                    }

                    if (member === memberInfo.member && member.membershipLevel === 'family') { // If 1st member check ok, call process over w/ 2nd member, if there's one.
                      return checkMember(member2);
                    }

                    else {   // Able to renew, continue checkout
                      resolve();
                    }
                  })
                  .catch((err) => { // Name & email don't macht or unexpected err. note: unexpected error are handled by outer catch block
                    if (typeof err.response === 'undefined' || err.response.status !== 404) reject();  // Unexpected error
                    if (err.response.status === 404) { // Name & email didn't macht
                      toast.error('The information you entered does not match our records. Please correct your information. Contact member services if the problem persists. ', {
                        onClose: () => history.replace('/')
                      });
                      reject();
                    }  
                  })
              })
              .catch((err) => { // Email not in db or unexpected err
                if (typeof err.response === 'undefined' || err.response.status !== 404) reject();  // Unexpected error
                if (err.response.status === 404 && member.membershipLevel !== 'family') resolve(); // No email, one member, continue checkout
                if (member.membershipLevel === 'family' && member !== member2) checkMember(member2); // No email, two members, 1st member, call func again w/ member2 as para
                if (err.response.status === 404 && member === member2) resolve(); // No email, 2nd member, continue checkout
              });
      
              // resolve() OR reject()
          })
      
          return getMember.then(() => {
                            return actions.resolve(); // Keep paypal window open
                          })
                          .catch(() => {
                            return actions.reject(); // Close paypal window
                          });
      
          // return actions.resolve();
          // return actions.reject();

        } // End checkMember
        checkMember(member);

      },

      // Call your server to set up the transaction
      createOrder: function(data, actions) {
        return http.post('/paypal/orders/create/', {  // TODO: Replace w/ shorter version, or extract in "orders service"
          membershipLevel: member.membershipLevel
        }) 
          .then(function(res) {
            const orderID = res.data.orderID;

            // Write form data to db
            unapprovedMemberService.addFormData(member, orderID);

            return orderID;
            
          }).then(function(orderID){
            if (member.membershipLevel === 'family') unapprovedMemberService.addFormData(member2, orderID);

            return orderID;
          })
      },

      // Call your server to finalize the transaction
      onApprove: function(data, actions) {
        return http.post('/paypal/orders/' + data.orderID + '/capture/') // TODO: Replace w/ shorter version, or extract in "orders service"
          .then(function(res) {
            return res;
          }).then(function(orderData) {
              // Three cases to handle:
              //   (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
              //   (2) Other non-recoverable errors -> Show a failure message
              //   (3) Successful transaction -> Show confirmation or thank you

              // This example reads a v2/checkout/orders capture response, propagated from the server
              // You could use a different API or structure for your 'orderData'
              var errorDetail = Array.isArray(orderData.details) && orderData.details[0];

              if (errorDetail && errorDetail.issue === 'INSTRUMENT_DECLINED') {
                  return actions.restart(); // Recoverable state, per:
                  // https://developer.paypal.com/docs/checkout/integration-features/funding-failure/
              }

              if (errorDetail) {
                  var msg = 'Sorry, your transaction could not be processed.';
                  if (errorDetail.description) msg += '\n\n' + errorDetail.description;
                  if (orderData.debug_id) msg += ' (' + orderData.debug_id + ')';
                  return alert(msg); // Show a failure message
              }

              // Show a success message
              // alert('Transaction completed by ' + orderData.payer.name.given_name);
              history.replace({
                pathname: '/membership/order/confirmation',
                memberInfo: memberInfo
              })
          });
      }
    }).render('#paypal-button-container');
  }

  render() { 
    return ( 
      <div id="paypal-button-container"></div>  // TODO: replace id with className ???
    );
  }
}
 
export default withRouter(PayPalBtn);