import React, { useState } from 'react';
import MembershipChoice from './membershipChoice';
import MembershipByMail from './membershipByMail';

function MailOrOnline({hideMailOrOnline}) {
  const [showMembershipByMail, setShowMembershipByMail] = useState(false);

  const showMail = (trueFalse) => setShowMembershipByMail(trueFalse);

  const choiceOrMail = showMembershipByMail ? 
    <MembershipByMail showMail={showMail} /> : 
    <MembershipChoice 
      hideMailOrOnline={hideMailOrOnline} 
      showMail={showMail}
    />;

  return (
    <React.Fragment>
      {choiceOrMail}
    </React.Fragment>
  );
}

export default MailOrOnline;