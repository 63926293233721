import React, { Component } from 'react';
import Paragraph from './paragraph';
import { StyledImageSkeletonOverlay } from './styles/imageSkeletonOverlay.styled';

class IntroCart extends Component {
  state = {
    isRendered: false
  };

  render() {
    const { name, title, image, paragraph } = this.props.member;

    const loaded = () => {
      this.setState({ isRendered: true });
    };
    const opacity = (this.state.isRendered) ? ' ' : 'hideFade'; 

    return ( 
      <div className="introCart">
        <div className="introCart-topRow">
          <div className="introCart-nameTitle">
            <h1 className="h1-cartSmall">{name}</h1>
            <h3 className="h3-cartSmall">{title}</h3>
          </div>
          <div className="introCart-img">
            <div className="introCart-img-container">
              <div className="introCart-img-wrap">
                <StyledImageSkeletonOverlay/>
                <img src={image.src} alt={image.alt} onLoad={loaded} className={`${opacity}`} />
              </div>
            </div>
          </div>
        </div>
        <div className="introCart-bottomRow p-cartSmall">
          <Paragraph text={paragraph} />
        </div>
      </div>
    );
  }
}
 
export default IntroCart;