import styled from 'styled-components';

export const StyledTopBarDiv = styled.div`
  background-color: #00CCFF;
  overflow: hidden;
  

  ${({showHide}) => (showHide === 'hide') && `
    display: none;
  `}

  /* Transition time has to match: StyledMain and StyledNavMobile. Also 2 places connected to this timeout in App.js  */
  ${({topBarTransition}) => topBarTransition && `
    transition: height 0.5s;
  `}

  /* min-height, in case there's not content inside topBar */
  ${({topBarClosed, height}) => !topBarClosed && `
    min-height: 3px;
    height: ${height};
  `}

  ${({topBarClosed}) => topBarClosed && `
    height: 3px;
  `}

  @media (min-width: 340px) {
    ${({topBarClosed, height}) => !topBarClosed && `
      min-height: 5px;
    `}

    ${({topBarClosed}) => topBarClosed && `
      height: 5px;
    `}
    margin-bottom: 5px;
  }
  
  @media (min-width: 550px) {
    ${({topBarClosed, height}) => !topBarClosed && `
      min-height: 8px;
    `}

    ${({topBarClosed}) => topBarClosed && `
      height: 8px;
    `}
    margin-bottom: 10px;
  }

`;