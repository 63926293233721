import React, { Component } from 'react';
import Crossfade from './crossfade';
import JoinUsRow from './joinUsRow';
import { getCrossfadeImagesByName } from './../services/crossfadeService';

class JoinUs extends Component {
  state = {
    crossfadeImages: []
  };

  componentDidMount() {
    window.scrollTo(0, 0);

    const setStateCrossfadeImages = async () => {
      const result = await getCrossfadeImagesByName('joinus');
      let imgArr = result.data[0].images;

      // Sort images by postion property.
      imgArr.sort((a,b) => (a.position > b.position) ? 1 : ((b.position > a.position) ? -1 : 0))

      this.setState({ crossfadeImages: imgArr });
    };
    setStateCrossfadeImages();  
  }

  render() { 
    return ( 
      <React.Fragment>
        <section className="joinUs">
          <div className="margin-container">
            <Crossfade crossfadeImages={this.state.crossfadeImages} />
          </div>
          <div className="margin-container">
            <JoinUsRow />
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default JoinUs;