import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { selectImage, spaceTo_ } from '../utils/helperFuncs';
import { StyledImageSkeletonOverlay } from './styles/imageSkeletonOverlay.styled';

class ArtistTile extends Component {
  state = {
    isRendered: false
  };

  render() { 
    const { firstName, lastName, images } = this.props.artist;
    const tileImg = selectImage(images, 'overview');

    const loaded = () => {
      this.setState({ isRendered: true });
    };
    const opacity = (this.state.isRendered) ? ' ' : 'hideFade'; 

    return (
      <Link 
        className="artistTile-link" 
        to={{ 
          pathname: `/artists/${spaceTo_(firstName).toLowerCase()}-${spaceTo_(lastName).toLowerCase()}`,
          state: {
            artist: this.props.artist
          }
        }}
      >
        <div className="artistTile">
          <div className="artistTile-imgContainer">
            <div className="artistTile-imgWrap">
              <StyledImageSkeletonOverlay/>
              <img src={tileImg.src} alt={tileImg.alt} onLoad={loaded} className={`${opacity}`} />
            </div>
          </div>
          <span className="smallText">{`${firstName} ${lastName}`}</span>
        </div>
      </Link>
    );
  }
}
 
export default ArtistTile;