import React, { Component } from 'react';
import { addFeaturedCartSm, getGalleryData, updateFeaturedCartSm } from '../../services/galleryService';
import Joi from 'joi-browser';
import { FormLabel, TextField, Button } from '@material-ui/core';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';


class FeaturedCartSmFrom extends Component {
  state = {
    file: null, // Image src
    selectedFile: null,
    galleryId: null,
    cart: {
      id: '',
      alt: '',
      firstName: '',
      lastName: '',
      paragraph: '',
      link: ''
    },
    errors: {}
  };

  componentDidMount() {
    window.scrollTo(0, 0);

    const fetchGalleryData = async () => {
      const result = await getGalleryData();
      const galleryId = result.data[0]._id;
      const { id } = this.props.match.params;

      if (id !== 'new') { // If edit
        const artists = result.data[0].show.artists;
        const filteredArtists = artists.filter(artist => {
          return artist._id === id;
        });
        const artist = filteredArtists[0];

        const file = artist.image.src;
        const tempCart = {
          id: artist._id,
          alt: artist.image.alt,
          firstName: artist.firstName,
          lastName: artist.lastName,
          paragraph: artist.paragraph,
          link: artist.link
        };
  
        this.setState({
          galleryId: galleryId,
          file: file,
          cart: tempCart
        });
      } else { // If new

        this.setState({ galleryId});
      }


    };
    fetchGalleryData();

  }

  theme = createMuiTheme ({
    typography: {
      fontFamily: "'Nunito', sans-serif",
      fontSize: 11.5
    },
    palette: {
      primary: {
        main: '#00CCFF'
      },
      secondary: {
        main: '#00CCFF'
      }
    }
  });

  schemaCreate = {
    selectedFile: Joi.object().label('Image'),
    firstName: Joi.string().max(50).trim().required().label('First Name'),
    lastName: Joi.string().max(50).trim().required().label('Last Name'),
    paragraph: Joi.string().max(2000).trim().required().label('Text'),
    link: Joi.string().trim().allow('').lowercase()
  }

  schemaUpdate = {
    selectedFile: Joi.label('Image'),
    firstName: Joi.string().max(50).trim().required().label('First Name'),
    lastName: Joi.string().max(50).trim().required().label('Last Name'),
    paragraph: Joi.string().max(2000).trim().required().label('Text'),
    link: Joi.string().trim().allow('').lowercase()
  }

  validate = () => {
    const { id } = this.props.match.params;
    let result = '';

    const toBeValidated = {
      selectedFile: this.state.selectedFile,
      firstName: this.state.cart.firstName,
      lastName: this.state.cart.lastName,
      paragraph: this.state.cart.paragraph,
      link: this.state.cart.link
    }

    if (id === 'new') {
      result = Joi.validate(toBeValidated, this.schemaCreate, { abortEarly: false });
      if (!result.error) return null;
    } else {
      result = Joi.validate(toBeValidated, this.schemaUpdate, { abortEarly: false });
      if (!result.error) return null;
    }

    const errors = {};
 
    for (let item of result.error.details) {
      errors[item.path[0]] = item.message;
    }

    return errors;
  };

  fileSelectedHandler = e => {
    if (e.target.files.length !== 0)
      this.setState({
        selectedFile: e.target.files[0],
        file: URL.createObjectURL(e.target.files[0])
      })
  }

  handleChange = e => {
    const cart = { ...this.state.cart };
    cart[e.target.name] = e.target.value;

    this.setState({ cart });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    const { id } = this.props.match.params;

    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) return;

    const setFeaturedCart = async () => {
      
      const formData = new FormData();

      if ((id === 'new') || (this.state.selectedFile !== null))  // Only add image to form, if a new cart is created OR if adding new image to existing cart.
        formData.append('featCartSmImage', this.state.selectedFile, this.state.selectedFile.name);

      formData.append('image[alt]', this.state.cart.alt);
      formData.append('firstName', this.state.cart.firstName);
      formData.append('lastName', this.state.cart.lastName);
      formData.append('paragraph', this.state.cart.paragraph);
      formData.append('link', this.state.cart.link);
      formData.append('id', this.state.galleryId);



      if (id === 'new') {
        // create
        try {
          await addFeaturedCartSm(formData);
          window.location = '/gallery';
        } catch (ex) {
          console.log(ex) // TODO: take out? axios interceptor should handle unexpected errors. Try/catch only needed for expected errors, eg 404
        }
      } else {
        // update
        try {
          await updateFeaturedCartSm(formData, id);
          window.location = '/gallery';
        } catch (ex) {
          if (ex.response && ex.response.status === 404)
            window.location = '/gallery';
          console.log(ex) // TODO: take out? axios interceptor should handle unexpected errors. Try/catch only needed for expected errors, eg 404
        }
      }

    };
    setFeaturedCart();

    // console.log('Submitted');
  }


  render() {
    const { cart, errors } = this.state;

    return (
      <ThemeProvider theme={this.theme}>
        <div className="margin-container-sm crud-form">
          <div className="crud-form-col">
            <h1>Artist In Show Form</h1>

            <form className="membership-form" onSubmit={this.handleSubmit}>

              <FormLabel style={{ fontSize: '1.1rem', fontWeight: 600, color: '#000' }} >Image Upload</FormLabel>
              <div className="input-wrap">
                <div className="img-upload">
                  <Button className="img-upload-button" variant="contained" component="label">
                    Choose Image
                    <input type="file" hidden onChange={this.fileSelectedHandler}/>
                  </Button>
                  <div className="image-preview">
                    <img src={this.state.file} alt="" />
                  </div>
                </div>
                {errors.selectedFile && <React.Fragment><br/><div style={{ fontSize: '.8rem', fontWeight: '600', color: '#f44336' }}>"Image" is not allowed to be empty</div><br/></React.Fragment>}
                <TextField value={cart.alt} onChange={this.handleChange} id="alt" name="alt" type="text" label="Alternative Text"  style={{ marginTop: '1.5em', width: '100%' }}/>
                <div style={{ fontSize: '.8rem', fontWeight: '600', color: '#007bff'}}>
                  Describe the image to a visual impaired person as <span style={{ textDecoration: 'underline' }}>precisely</span> as possible with as <span style={{ textDecoration: 'underline' }}>few words</span> possible.
                </div>
              </div>

              <FormLabel style={{ fontSize: '1.1rem', fontWeight: 600, color: '#000' }} >Name and Text</FormLabel>
              <div className="input-wrap">
                <TextField error={!!errors.firstName} helperText={errors.firstName} value={cart.firstName} onChange={this.handleChange} id="firstName" name="firstName" type="text" label="First Name"  style={{ width: '100%' }}/>
                <TextField error={!!errors.lastName} helperText={errors.lastName} value={cart.lastName} onChange={this.handleChange} id="lastName" name="lastName" type="text" label="Last Name"  style={{ marginTop: '1.5em', width: '100%' }}/>

                <TextField error={!!errors.paragraph} helperText={errors.paragraph} value={cart.paragraph} onChange={this.handleChange} id="paragraph" name="paragraph" type="text" label="Text"  multiline rows={5} rowsMax={10} style={{ marginTop: '2em', width: '100%' }}/>
                <div style={{ fontSize: '.8rem', fontWeight: '600', color: '#007bff'}}>
                  Either very brief text. Or use starting paragraph from artists profile and link to profile.
                </div>
              </div>

              <FormLabel style={{ fontSize: '1.1rem', fontWeight: 600, color: '#000' }} >Link to Artists Profile (optional)</FormLabel>
              <div className="input-wrap">  
                <TextField value={cart.link} onChange={this.handleChange} id="link" name="link" type="text" label="Link"  style={{ marginTop: '1.3em', width: '100%' }}/>
                <div style={{ fontSize: '.8rem', fontWeight: '600', color: '#007bff'}}>
                  Link has to start with &nbsp;/artists/ <br />
                  <span style={{ fontWeight: '300', color: '#000'}}>Example: &nbsp;/artists/mike-smith</span> <br />
                </div>
              </div>

              <Button type="submit" variant="contained" color="primary" style={{borderRadius: '4px', marginTop: '1.5em', width: '200px', height: '50px', fontSize: '1.1rem', fontWeight: '600'}}>
                Save
              </Button>
            </form>
          </div>
        </div>
      </ThemeProvider>
    );
  }
}

export default FeaturedCartSmFrom;