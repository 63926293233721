import React, { Component } from 'react';

class NotFound extends Component {
  state = {  }
  render() { 
    return ( 
      <div>Page not found.</div>
    );
  }
}
 
export default NotFound;