import React, { Component } from 'react';

class MainAndSide extends Component {
  render() {
    const { paragraphs, main, side } = this.props;
    let firstHeading = null;
    let renderAdditionalInfo = true;

    // If 1st paragraph available for render.
    if (paragraphs[0]) {
      // Render 1st heading, if it's not an empty string.
      firstHeading = paragraphs[0].heading ? (
        <h2>{paragraphs[0].heading}</h2>
      ) : null;
    }

    // If additionalInfo available for render.
    if (side.props.additionalInfo[0]) {
      // If heading and text are both empty in the additionalInfo section.
      if (
        side.props.additionalInfo[0].heading === '' &&
        side.props.additionalInfo[0].text === ''
      )
        renderAdditionalInfo = false;
    }

    return (
      <div className="bottomMargin-container">
        {firstHeading}

        <div className="aboutCart-row">
          <div className="aboutCart-p">{main}</div>
          {renderAdditionalInfo && (
            <div className="aboutCart-sideInfo">{side}</div>
          )}
        </div>
      </div>
    );
  }
}

export default MainAndSide;
