import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { StyledNavMobile } from './styles/navMobile.styled';

class NavMobile extends Component {
  render() {
    let activeClass = '';
    activeClass = (this.props.navMobActive) ? 'navMobile-active' : ' ';

    return (
      <StyledNavMobile 
        className={`navMobile ${activeClass}`}
        topBarClosed={this.props.topBarClosed}
        mainTopMargin={this.props.mainTopMargin}
        mainTransition={this.props.mainTransition}
      >
        <div className="navMobile-margin-container">
          <ul>
            <li><Link to="/gallery" onClick={this.props.toggleNavMobActive}>Gallery</Link></li>
            <li><Link to="/artists" onClick={this.props.toggleNavMobActive}>Artists</Link></li>
            <li><Link to="/events" onClick={this.props.toggleNavMobActive}>Events</Link></li>
            <li><Link to="/about" onClick={this.props.toggleNavMobActive}>About</Link></li>
            <li><Link to="/contact" onClick={this.props.toggleNavMobActive}>Contact</Link></li>
          </ul>
          <div className="joinUsColumn-btn navMobile-btn">
            <Link to="/join" className="nav-join" onClick={this.props.toggleNavMobActive}>Join Us</Link>
          </div>
        </div>
      </StyledNavMobile>
    );
  }
}
 
export default NavMobile;