import React, { Component } from 'react';
import GallerySideInfo from './gallerySideInfo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookSquare } from '@fortawesome/free-brands-svg-icons'
import { faTwitter } from '@fortawesome/free-brands-svg-icons'
import { faInstagram } from '@fortawesome/free-brands-svg-icons'

class ContactCart extends Component {

  render() {
    const { galleryData } = this.props;
    const { heading, address, phone, email, social } = this.props.contactData;

    return (
      <div className="bottomMargin-container">
        <h2>{heading}</h2>
        <div className="aboutCart-row">
          <div className="aboutCart-p">
            <address>
              <span className="line1">{address.street}</span><br />
              <span className="line2">{address.city}</span><br />
              <span className="line2">{address.state} {address.zip}</span><br />
              <a className="line3" href={`tel:+1-${phone}`}>{phone}</a><br /> {/* TODO: test if phone link is working with dots btw digits */}
              <a className="line4" href={`mailto:${email}`}>{email}</a><br />
              <div className="contactCart-social">
                { social.facebook && <a href={social.facebook} className="footer-icon-fb" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faFacebookSquare} /></a> }
                { social.twitter && <a href={social.twitter} className="footer-icon-twitter" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faTwitter} /></a> }
                { social.instagram && <a href={social.instagram} className="footer-icon-ig" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faInstagram} /></a> }
              </div>
            </address>
          </div>
          <GallerySideInfo galleryData={galleryData} />
        </div>
      </div>
    );
  }
}
 
export default ContactCart;