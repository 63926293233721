import React, { Component } from 'react';
import ScholorshipInfo from './scholorshipInfo';
import { StyledImageSkeletonOverlay } from './styles/imageSkeletonOverlay.styled';
import ImgNextGen from './imgNextGen';

class Scholorship extends Component {
  state = {
    isRendered: false
  };

  render() {
    const { scholorships } = this.props;
    const { image } = scholorships;

    const loaded = () => {
      this.setState({ isRendered: true });
    };
    const opacity = (this.state.isRendered) ? ' ' : 'hideFade'; 

    return ( 
      <div className="infoCartImgRight">
        <h2>{scholorships.heading}</h2>
        <div className="infoCartImgRight-row">
          <div className="infoCartImgRight-img">
            <div className="infoCartImgRight-img-container">
              <div className="infoCartImgRight-img-wrap">
                <StyledImageSkeletonOverlay />
                <ImgNextGen 
                  srcWebp={image.srcWebp}
                  fallback={image.src}
                  alt={image.alt}
                  onLoad={loaded}
                  className={`${opacity}`}
                />
                {/* <img src={scholorships.image.src} alt={scholorships.image.alt} onLoad={loaded} className={`${opacity}`} /> */}
              </div>
            </div>
          </div>
          <div className="infoCartImgRight-info">
            <ScholorshipInfo scholorships={scholorships} />
          </div>
        </div>
      </div>
    );
  }
}
 
export default Scholorship;