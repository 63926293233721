import React, { Component } from 'react';
import { StyledImageSkeletonOverlay } from './styles/imageSkeletonOverlay.styled';

class ArtistTileImg extends Component {
  state = {
    isRendered: false
  };

  render() {
    const { image } = this.props;

    const loaded = () => {
      this.setState({ isRendered: true });
    };
    const opacity = (this.state.isRendered) ? ' ' : 'hideFade'; 

    return ( 
      <div className="artistTile-imgContainer">
        <div className="artistTile-imgWrap">
          <StyledImageSkeletonOverlay/>
          <img src={image.src} alt={image.alt} onLoad={loaded} className={`${opacity}`} />
        </div>
      </div>
    );
  }
}
 
export default ArtistTileImg;