import React, { Component } from 'react';
import PayPalBtn from './payPalBtn';
import { ToastContainer } from 'react-toastify';


class MembershipOrder extends Component {
  
  render() {
    const { memberInfo } = this.props.location;
    if (typeof memberInfo === 'undefined') window.location = '/membership';  // If direct visit

    let { member, member2, membershipPrice } = memberInfo;

    member2 = (member.membershipLevel === 'family') ? member2 : null;

    return (
      <section className="membership">
        <ToastContainer position="top-left" autoClose="15000" />
        <div className="margin-container-narrow membership-container">
          <div className="membership-form-col">
            <h3 className="h3">Order Summary</h3>
            <div className="input-wrap">
              <div className="orderSummary-membership"><span>{member.membershipLevel} Membership</span><strong>${membershipPrice}</strong></div>
              <div className="orderSummary-term">Term: 1 year <span className="smallText">(effective Jan 1 - Dec 31)</span></div>
              <div className="orderSummary-name">For: {member.firstName} {member.lastName} {member2 && `and ${member2.firstName2} ${member2.lastName2}`}</div>
              <div className="orderSummary-total"><span>Billed Today</span><strong>${membershipPrice}</strong></div>
            </div>
            <div className='payPalBtns-wrap'>
              <PayPalBtn memberInfo={memberInfo} />
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default MembershipOrder;