import React, { Component } from 'react';
import { Route, Switch, Redirect, withRouter } from 'react-router-dom';
import Header from './components/header';
import Footer from './components/footer';
import Home from './components/home';
import Gallery from './components/gallery';
import Artists from './components/artists';
import Artist from './components/artist';
import Events from './components/events';
import About from './components/about';
import Contact from './components/contact';
import JoinUs from './components/joinUs';
import OrderConfirmation from './components/orderConfirmation';
import MembershipOrder from './components/membershipOrder';
import Membership from './components/membership';
import Members from './components/members';
import ArtAroundTown from './components/artAroundTown';

import FeaturedCartForm from './components/forms/featuredCartForm';

/* Only remove comment for short time to register new users. */
import RegisterForm from './components/forms/registerForm';

import FeaturedCartSmForm from './components/forms/featuredCartSmForm';

import NotFound from './components/notFound';
import LoginForm from './components/loginForm';
import Logout from './components/logout';
import auth from './services/authService';
import { getGalleryData } from './services/galleryService';
import { getContactData } from './services/contactService';
import { getTopBarData } from './services/topBarService';
import './App.sass';

// import UpdateCrossfadeLanding from './components/updateCrossfadeLanding';
import { StyledMain } from './components/styles/main.styled';

class App extends Component {
  state = {
    user: '',
    galleryData: '', // TODO: Create empty state model of data. So check if data available won't be needed.
    contactData: {
      image: {
        src: '',
        alt: '',
      },
      heading: '',
      address: {
        street: '',
        city: '',
        state: '',
        zip: '',
      },
      phone: '',
      email: '',
      social: {
        facebook: '',
        twitter: '',
        instagram: '',
      },
    },
    topBarHeight: 'auto',
    topBarClosed: false,
    topBarTransition: true,
    mainTopMargin: 0,
    mainTransition: false,
    topBarSettings: {
      showTopBar: false,
      linkTo: '',
      text: '',
    },
  };

  childRef = React.createRef(); // TODO: check if it has to go in constructor?

  // When topBar is closed.
  // Set height from auto to the calculated height (which should be same as auto height). Reason: we can't transition from auto to a different height.
  // Set topBarClosed-flag to true, so styled-components takes hardcoded values (values w/out content in topBar) instead of larger calculated value.
  // Also set transition-flags for topBar and main-div.
  handleTopBarClose = () => {
    const getTopBarHeight = () => {
      if (!this.childRef.current) return;
      const topBarHeight = this.childRef.current.clientHeight;
      this.setState({ topBarHeight });
    };
    getTopBarHeight();

    setTimeout(() => {
      this.setState({ mainTransition: true });
    }, 10);

    setTimeout(() => {
      this.setState({ topBarClosed: true });
    }, 20); // Slight delay needed, so we can switch from auto hight to calculated height, then transition. otherwise transition won't work from auto to other height.

    setTimeout(() => {
      this.setState({ topBarTransition: false }); // So it won't transition after closing.
      this.setState({ mainTransition: false }); // So it won't transition after closing.
    }, 1000); // TODO: should be same as transition of topBar height transition
  };

  componentDidMount() {
    setTimeout(() => {
      // TODO: cancel timeout in componentWillUnmount?? or not needed cause everything inside App.js ?
      window.scrollTo(0, 0);
    }, 550); // TODO: should be tat larger than transition of topBar

    // Set topMargin for main div, to push content down. Needed cause header is fixed positioned.
    const getTopBarHeight = () => {
      if (!this.childRef.current) return;
      const topBarHeight = this.childRef.current.clientHeight;
      if (!topBarHeight) return;
      this.setState({ mainTopMargin: topBarHeight });
    };
    getTopBarHeight();
    // this set timeout is needed when using the donate-btn inside topbar, so hero is not covered by large topbar on mobile screen
    setTimeout(() => {
      getTopBarHeight();
    }, 2500);
    window.addEventListener('resize', getTopBarHeight);
    // TODO: cancel event listener in clean up

    const getDataThenSetState = async () => {
      const user = auth.getCurrentUser();

      const result = await getGalleryData();
      const galleryData = result.data[0];

      const resultContact = await getContactData();
      let contactData = resultContact.data;

      const image = contactData.image;
      const heading = contactData.heading;
      const address = contactData.address;
      const phone = contactData.phone;
      const email = contactData.email;
      const social = contactData.social;
      contactData = { image, heading, address, phone, email, social };

      const resultTopBar = await getTopBarData();
      let topBarData = resultTopBar.data[0];

      this.setState({
        user: user,
        galleryData: galleryData,
        contactData: contactData,
        topBarSettings: topBarData,
      });
    };
    getDataThenSetState();
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      // Re-renders when url changes.
      const getTopBarHeight = () => {
        if (!this.childRef.current) return;
        const topBarHeight = this.childRef.current.clientHeight;
        if (!topBarHeight) return;
        this.setState({ mainTopMargin: topBarHeight });
      };
      getTopBarHeight();
    }
  }

  render() {
    const path = this.props.location.pathname;
    const sm =
      path === '/membership' ||
      path === '/membership/order' ||
      path === '/docentlogin' ||
      path === '/members'
        ? '-sm'
        : ' ';

    const { user } = this.state;

    return (
      <React.Fragment>
        <Header
          user={user}
          childRef={this.childRef}
          handleTopBarClose={this.handleTopBarClose}
          topBarHeight={this.state.topBarHeight}
          topBarClosed={this.state.topBarClosed}
          topBarTransition={this.state.topBarTransition}
          mainTopMargin={this.state.mainTopMargin}
          mainTransition={this.state.mainTransition}
          topBarSettings={this.state.topBarSettings}
        />
        <StyledMain
          sm={sm}
          mainTopMargin={this.state.mainTopMargin}
          topBarClosed={this.state.topBarClosed}
          mainTransition={this.state.mainTransition}
        >
          {/* <main className={`main${sm}`}> */} {/* TODO: take out */}
          <Switch>
            <Route
              path="/docentlogin"
              render={(props) => {
                if (user) return <Redirect to="/" />;
                return <LoginForm {...props} />;
              }}
            />
            <Route
              path="/register"
              render={(props) => {
                if (!user) return <Redirect to="/" />;
                return <RegisterForm {...props} />;
              }}
            />

            <Route path="/logout" component={Logout} />
            <Route
              path="/gallery"
              render={(props) => (
                <Gallery
                  user={user}
                  galleryData={this.state.galleryData}
                  {...props}
                />
              )}
            />
            <Route path="/artists/:firstName-:lastName" component={Artist} />
            <Route path="/artists" component={Artists} />
            <Route path="/events" component={Events} />
            <Route path="/about" component={About} />
            <Route
              path="/contact"
              render={(props) => (
                <Contact
                  galleryData={this.state.galleryData}
                  contactData={this.state.contactData}
                  {...props}
                />
              )}
            />
            <Route path="/join" component={JoinUs} />
            <Route
              path="/membership/order/confirmation"
              component={OrderConfirmation}
            />
            <Route path="/membership/order" component={MembershipOrder} />
            <Route path="/membership" component={Membership} />
            <Route
              path="/members"
              render={(props) => {
                // if (!user.isAdmin && user.role !== 'membersAdmin') return <Redirect to="/" />
                if (
                  !user.isAdmin &&
                  !user.role?.some((elem) => ['membersAdmin'].includes(elem))
                )
                  return <Redirect to="/" />;
                return <Members {...props} />;
              }}
            />
            <Route path="/art-around-town" component={ArtAroundTown} />
            {/* <Route path="/update-crossfade-landing" component={UpdateCrossfadeLanding} /> */}
            <Route
              path="/featuredcarts/:id"
              render={(props) => {
                // if (!user.isAdmin && user.role !== 'featuredCartsAdmin') return <Redirect to="/" />
                if (
                  !user.isAdmin &&
                  !user.role?.some((elem) =>
                    ['featuredCartsAdmin'].includes(elem)
                  )
                )
                  return <Redirect to="/" />;
                return <FeaturedCartForm {...props} />;
              }}
            />
            <Route
              path="/featuredcartsm/:id"
              render={(props) => {
                // if (!user.isAdmin && user.role !== 'galleryAdmin') return <Redirect to="/" />
                if (
                  !user.isAdmin &&
                  !user.role?.some((elem) => ['galleryAdmin'].includes(elem))
                )
                  return <Redirect to="/" />;
                return <FeaturedCartSmForm {...props} />;
              }}
            />
            <Route path="/not-found" component={NotFound} />
            <Route
              exact
              path="/"
              render={(props) => <Home user={user} {...props} />}
            />
            <Redirect to="/not-found" />
          </Switch>
        </StyledMain>
        <Footer
          galleryData={this.state.galleryData}
          contactData={this.state.contactData}
        />
      </React.Fragment>
    );
  }
}

export default withRouter(App);
