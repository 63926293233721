import React, { Component } from 'react';
import Crossfade from './crossfade';
import StudioTourAbout from './studioTourAbout';
import { getCrossfadeImagesByName } from './../services/crossfadeService';
import { getEvents } from './../services/eventService';
import PleinAir from './pleinAir';
import { StyledImageSkeletonOverlay } from './styles/imageSkeletonOverlay.styled';
// import LifeDrawing from './lifeDrawing';
// import LinoCut from './linoCut';

class Events extends Component {
  state = {
    crossfadeImages: [],
    studioTour: {
      name: '',
      image: {
        src: '',
        alt: '',
      },
      heading: '',
      paragraphs: [],
      additionalInfo: [],
    },
    pleinAir: {
      name: '',
      image: {
        src: '',
        alt: '',
      },
      heading: '',
      paragraphs: [],
      additionalInfo: [],
    },
    lifeDrawing: {
      name: '',
      image: {
        src: '',
        alt: '',
      },
      heading: '',
      paragraphs: [],
      additionalInfo: [],
    },
    isRendered: false,
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    // setTimeout(() => {
    //   window.scrollTo(0, 0);
    // }, 800);

    const setStateCrossfadeImages = async () => {
      const result = await getCrossfadeImagesByName('studiotour');
      let imgArr = result.data[0].images;

      // Sort images by postion property.
      imgArr.sort((a, b) =>
        a.position > b.position ? 1 : b.position > a.position ? -1 : 0
      );

      this.setState({ crossfadeImages: imgArr });
    };
    setStateCrossfadeImages();

    // const getEventDataThenSetState = async () => {
    //   const result = await getEventByName('studiotour');
    //   const studioTour = result.data;

    //   this.setState({ studioTour });
    // };
    // getEventDataThenSetState();

    const getEventDataThenSetState = async () => {
      const result = await getEvents();
      const events = result.data;
      const studioTour = events.find((item) => item.name === 'studiotour');
      const pleinAir = events.find((item) => item.name === 'pleinair');
      const lifeDrawing = events.find((item) => item.name === 'lifedrawing');

      this.setState({ studioTour, pleinAir, lifeDrawing });
    };
    getEventDataThenSetState();
  }

  render() {
    const { studioTour, crossfadeImages, pleinAir } = this.state;

    const loaded = () => {
      this.setState({ isRendered: true });
    };
    const opacity = this.state.isRendered ? ' ' : 'hideFade';

    return (
      <div className="topMargin-container">
        {/* EventCalendar Section */}
        <section className="bottomMargin-container">
          <div className="margin-container-sm">
            <h1>Event Calendar</h1>
          </div>
          <div className="margin-container-none margin-container-sm">
            <div className="calendar-container">
              <div className="calendar-wrap">
                <StyledImageSkeletonOverlay />
                <iframe
                  className={`calendar calendar-agendaView ${opacity}`}
                  onLoad={loaded}
                  title="event calendar list view"
                  src="https://calendar.google.com/calendar/embed?height=600&wkst=1&bgcolor=%23ffffff&ctz=America%2FLos_Angeles&mode=AGENDA&showTitle=0&showTabs=0&showTz=0&src=MzY5MzI3N2ZlZmExZGVlOTE4ZmJlZDQ4YTdiMGU1ZTc1OTQwMGZkNmRkY2FmMjZlNzEyOGE4MjgwMDMxZDJkNUBncm91cC5jYWxlbmRhci5nb29nbGUuY29t&color=%23D50000"
                  style={{ borderWidth: 0 }}
                  width="800"
                  height="600"
                  frameBorder="0"
                  scrolling="no"
                ></iframe>
                <iframe
                  className={`calendar calendar-monthView ${opacity}`}
                  onLoad={loaded}
                  title="event calendar month view"
                  src="https://calendar.google.com/calendar/embed?height=600&wkst=1&bgcolor=%23ffffff&ctz=America%2FLos_Angeles&showTitle=0&showTabs=0&showTz=0&src=MzY5MzI3N2ZlZmExZGVlOTE4ZmJlZDQ4YTdiMGU1ZTc1OTQwMGZkNmRkY2FmMjZlNzEyOGE4MjgwMDMxZDJkNUBncm91cC5jYWxlbmRhci5nb29nbGUuY29t&color=%23D50000"
                  style={{ borderWidth: 0 }}
                  width="800"
                  height="600"
                  frameBorder="0"
                  scrolling="no"
                ></iframe>
              </div>
            </div>
            <span className="smallText" style={{ maxWidth: '400px' }}>
              (If you're having trouble viewing the calendar, please sign out of
              your Google account or open the page in incognito mode.)
            </span>
          </div>
        </section>

        {/* Kids Events Section */}
        {/* <section className="bottomMargin-container">
          <div className="margin-container-sm">
            <h1>Kids Art Classes</h1>
            <p className="aboutCart-p">
              Tahoe Art League presents Kids Art Classes in the forest behind
              our Gallery, every Thursday in July. Local artists will teach
              painting with acrylics on canvas, watercolors on paper and
              painting on silk. Morning sessions 11am to 1pm; afternoon sessions
              2pm to 4pm. $25 for one child, $40 for two, $60 for 3. Bring
              snacks and lunch plus water bottle. Price includes tour of the new
              Summer Art Show in the Gallery. RSVP required as space is limited.
              <br />
              <br />
              Please{' '}
              <a
                href="/assets/TAL-KidsClasses-July2022.pdf"
                target="_blank"
                rel="noreferrer"
              >
                view flyer for details
              </a>
              .
            </p>
          </div>
        </section> */}

        {/* Only render comp if fetched data not undefined. E.g. don't render if no data for LifeDrawing in db. 
            Note, in getEventDataThenSetState, axiosInterceptor won't catch the fact that pleinAir data not in db, because we're succeeding in getting all events. That's why it'll return undefined for PleinAir, thus we need to check for that case.  */}
        {/* {lifeDrawing && <LifeDrawing lifeDrawing={lifeDrawing} />} */}

        {/* <LinoCut /> */}

        <section className="studioTour bottomMargin-container" id="studiotour">
          <div className="margin-container-sm">
            {/* <h1>{studioTour.heading}</h1> */}
            <h1>Artist Studio Tour</h1>
            <Crossfade crossfadeImages={crossfadeImages} />
            <StudioTourAbout studioTour={studioTour} />
          </div>
          {/* Map Section */}
          {/* <div
            className="margin-container-sm"
            id="studioTourMap"
            style={{ marginBottom: '50px' }}
          >
            <h2>Studio Tour Location Map</h2>

            <div style={{ display: 'flex' }}>
              <span
                style={{ color: '#666666' }}
                className="material-icons-round"
              >
                file_download
              </span>
              <a
                style={{ marginTop: '-2px', marginLeft: '3px' }}
                download="studioTourMap2023.jpg"
                href="/assets/studioTourMap2023.jpg"
                title="Studio Tour Map"
              >
                Download Map
              </a>
            </div>

            <div>
              <a
                href="/assets/studioTourMap2023-web.jpg"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  style={{ width: '100%' }}
                  src="/assets/studioTourMap2023-web.jpg"
                  alt="studio tour location map"
                />
              </a>
            </div>
          </div> */}
          {/* Google Map Section */}
          {/* <div className="margin-container-sm">
            <h2>Studio Tour Google Map</h2>
          </div>
          <div className="margin-container-none margin-container-sm">
            <div className="studioTour-map-container">
              <div className="studioTour-map-wrap">
                <iframe
                  className="studioTour-map"
                  title="google map with events for studio tour"
                  src="https://www.google.com/maps/d/embed?mid=1CENzvaV5SFDbeL5ncT9fkws6zizJyNw&ehbc=2E312F"
                ></iframe>
              </div>
            </div>
          </div> */}
        </section>

        {/* Only render comp if fetched data not undefined. E.g. don't render if no data for PleinAir in db. 
            Note, in getEventDataThenSetState, axiosInterceptor won't catch the fact that pleinAir data not in db, because we're succeeding in getting all events. That's why it'll return undefined for PleinAir, thus we need to check for that case.  */}
        {pleinAir && <PleinAir pleinAir={pleinAir} />}
      </div>
    );
  }
}

export default Events;
