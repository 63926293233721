import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class JoinUsColumn extends Component {
  render() {
    const { heading, list, button, type } = this.props.columnData;

    return ( 
      <div className="joinUsColumn">
        <div className="joinUsColumn-heading">
          <h1 className="h1-caps">{heading}</h1>
        </div>
        <div className="joinUsColumn-bulletList">
          <ul>
            {list.map((item, index) => (
              <li key={index}>
                <span className="joinUsColumn-circle"><i className="fa fa-circle"></i></span>
                <span className="joinUsColumn-text">{item}</span>
              </li>
            ))}
          </ul>
        </div>

        {(type !== 'donate') &&
        <div className="joinUsColumn-btn">
          {/* TODO: Reg links, put specific instruction to Admin. To properly write link e.g. either none; start w '/' or start w 'https://' */}
          {/* {button.link.charAt(0) === '/' && <Link to={button.link}>{button.lable}</Link>} */}  
          {button.link.charAt(0) === '/' &&
            <Link 
              to={{ 
                pathname: button.link,
                showMailOrOnline: true
              }}
            >
              {button.lable}
            </Link>
          }
          {button.link.charAt(0) === 'h' && <a href={button.link}>{button.lable}</a>}
        </div>
        }

        {(type === 'donate') &&
          <div className="joinUsColumn-btn">
            <form action="https://www.paypal.com/donate" method="post" target="_top">
              <input type="hidden" name="hosted_button_id" value="UPHDFMH25PMCG" />
              <input className="joinUs-submit" type="submit" value={button.lable} />
            </form>
          </div>
        }
      </div>
    );
  }
}
 
export default JoinUsColumn;


