import React, { Component } from 'react';
import ArtistTile from './artistTile';
import { getArtists } from '../services/artistService';

class Artists extends Component {
  state = {
    artists: [],
  };

  componentDidMount() {
    window.scrollTo(0, 0);

    const getArtistsSetState = async () => {
      const result = await getArtists();
      const artists = result.data;

      this.setState({ artists });
    };
    getArtistsSetState();

    // const artistsSorted = artists.sort(function(a, b) {
    //   var textA = a.name.last.toUpperCase();
    //   var textB = b.name.last.toUpperCase();
    //   return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
    // });
    // this.setState({ artists: artistsSorted });
  }

  render() {
    // render nothing if no data from server (conditional rendering)
    if (this.state.artists.length === 0) return null;

    const { artists } = this.state;

    return (
      <React.Fragment>
        <div className="margin-container margin-container-none">
          <section className="artists-gridSixCol">
            {artists.map((artist) => {
              return !artist.hidden ? (
                <ArtistTile artist={artist} key={artist._id} />
              ) : null;
            })}
          </section>
        </div>
      </React.Fragment>
    );
  }
}

export default Artists;
