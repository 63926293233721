import React, { Component } from 'react';
import { selectImage } from '../utils/helperFuncs';
import { StyledImageSkeletonOverlay } from './styles/imageSkeletonOverlay.styled';

class ArtistIntro extends Component {
  state = {
    isRendered: false
  };

  render() {
    const { firstName, lastName, images } = this.props.artist;
    const mainImg = selectImage(images, 'main');

    const loaded = () => {
      this.setState({ isRendered: true });
    };
    const opacity = (this.state.isRendered) ? ' ' : 'hideFade'; 

    return ( 
      <section className="artistIntro">
        <div className="margin-container">
          <div className="artistIntro-row">
            <div className="artistIntro-col1">
              <div className="artistIntro-name">
                <h1 className="h1-extraLarge">{`${firstName} ${lastName}`}</h1>
              </div>
            </div>
            <div className="artistIntro-col2">
              <div className="artistIntro-imgMaxWidth">
                <div className="artistIntro-imgContainer">
                  <div className="artistIntro-imgWrap">
                    <StyledImageSkeletonOverlay/>
                    <img src={mainImg.src} alt={mainImg.alt} onLoad={loaded} className={`${opacity}`} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
 
export default ArtistIntro;