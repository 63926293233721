import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
import "../css/crossfader.sass";
import { StyledImageSkeletonOverlay } from './styles/imageSkeletonOverlay.styled';
import ImgNextGen from './imgNextGen';

class Crossfade extends Component {
  state = {
    isRendered: false
  };

  render() {

    const loaded = () => {
      this.setState({ isRendered: true });
    };

    const opacity = (this.state.isRendered) ? ' ' : 'hideFade'; 

    return ( 
      <React.Fragment>
        {/* <Link to="/update-crossfade-landing" className="admin-btn">Update Images</Link> */}

        <div className="crossfade-hero">
          {this.props.crossfadeImages.map((img, index) => (
            <div className={`crossfade-imgWrap crossfade-imgWrap${index+1}`} key={img._id}>
              <StyledImageSkeletonOverlay />
              <ImgNextGen 
                srcWebp={img.srcWebp}
                srcWebpSm={img.srcWebpSm}
                fallback={img.src}
                alt={img.alt}
                onLoad={loaded}
                className={`${opacity}`}
              />
              {/* <img src={img.src} alt={img.alt} onLoad={loaded} className={`${opacity}`}/> */}
            </div>
          ))}
        </div>
      </React.Fragment>
    );
  }
}

export default Crossfade;