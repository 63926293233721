import React, { Component } from 'react';
import IntroCart from './introCart';

class Board extends Component {
  state = { 
    heading: '',
    members: [
      { 
        _id: 'asdfsdfkj1',
        name: 'Mikki Wilson',
        title: 'President',
        image: {
          src: '/uploads/gallery6.jpg',
          alt: 'board president'
        },
        para: 'Lorem ipsum dolor sit amet cvelit maxime pariatur quisquam culpa praesentium fuga facere sapiente deleniti consequuntur. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Magni pariatur numquam corrupti perferendis accusamus ea nostrum impedit asperiores animi, officiis vero quia hic. Fugiat unde voluptatem ipsum eum excepturi Lorem ipsum dolor sit amet consectetur adipisicing elit. Necessitatibus, officia facere obcaecati fugiat voluptates suscipit esse reprehenderit eaque iure facilis quia impedit, commodi, illum perspiciatis! Possimus quod ipsam magni eos!'
      },
      { 
        _id: 'asdfsdfkj2',
        name: 'Catherine Humphrey Bogart',
        title: 'Vice President',
        image: {
          src: '/uploads/gallery5.jpg',
          alt: 'board president'
        },
        para: 'um fuga facere sapiente deleniti consequuntur. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Magni pariatur numquam corrupti perferendis accusamus ea nostrum impedit asperiores animi, officiis vero quia hic. Fugiat unde voluptatem ipsum eum excepturi Lorem ipsum dolor sit amet consectetur adipisicing elit. Necessitatibus, officia facere obcaecati fugiat voluptates suscipit esse reprehenderit eaque iure facilis quia impedit, commodi, illum perspiciatis! Possimus quod ipsam magni eos!'
      },
      { 
        _id: 'asdfsdfkj3',
        name: 'Copt Forrest',
        title: 'Treasurer',
        image: {
          src: '/uploads/events6.jpg',
          alt: 'board president'
        },
        para: 'um fuga facere adipisicing elit. Magni pariatur numquam corrupti perferendis accusamus ea nostrum impedit asperiores animi, officiis vero quia hic. Fugiat unde voluptatem ipsum eum excepturi Lorem ipsum dolor sit amet consectetur adipisicing elit. Necessitatibus, officia facere obcaecati fugiat voluptates suscipit esse reprehenderit eaque iure facilis quia impedit, commodi, illum perspiciatis! Possimus quod ipsam magni eos!'
      }
    ]
  };

  render() {
    const { heading, members } = this.props.board;

    return ( 
      <div className="boardOfDirectors">
        <h2>{heading}</h2>
        <div className="boardOfDirectors-grid">
          {members.map(member => (
            <IntroCart member={member} key={member._id} />
          ))}
        </div>
    </div>
    );
  }
}
 
export default Board;