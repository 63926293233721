import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function MemberDialog({
  openDialog: open,
  handleCloseDialog: handleClose,
  member,
}) {
  const scroll = 'paper';

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const newReturning = member.newMember ? 'New' : 'Returning';

  return (
    <div>
      <Dialog
        open={open}
        // onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">
          <h2
            style={{
              fontFamily: 'Roboto',
              margin: '10px 0',
              textAlign: 'center',
            }}
          >
            {member.firstName} {member.lastName}
          </h2>
        </DialogTitle>
        <DialogContent dividers={scroll === 'paper'}>
          {/* Contact Section */}
          <section>
            <h3
              style={{
                textDecoration: 'underline',
                fontFamily: 'Roboto',
                marginTop: '20px',
              }}
            >
              Contact
            </h3>
            <div style={{ maxwidth: '600px' }}>
              {/* Phone row */}
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  // background: '#f2f2f2',
                  padding: '7px',
                }}
              >
                <div
                  style={{
                    flexBasis: '28%',
                    paddingRight: '5px',
                    margin: '0',
                    fontFamily: 'Roboto',
                  }}
                  className="infoText"
                >
                  Phone:
                </div>
                <div
                  style={{
                    fontSize: '16px',
                    fontFamily: 'Roboto',
                    flexBasis: '70%',
                  }}
                >
                  {member.phone}
                </div>
              </div>
              {/* Address row */}
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  background: '#f2f2f2',
                  padding: '7px',
                }}
              >
                <div
                  style={{
                    flexBasis: '28%',
                    paddingRight: '5px',
                    margin: '0',
                    fontFamily: 'Roboto',
                  }}
                  className="infoText"
                >
                  Address:
                </div>
                <div
                  style={{
                    fontSize: '16px',
                    fontFamily: 'Roboto',
                    flexBasis: '70%',
                  }}
                >
                  {member.street}
                  <br />
                  {member.city} {member.state}, {member.zip}
                </div>
              </div>
            </div>
          </section>
          {/* Profile Info Section */}
          <section>
            <h3
              style={{
                textDecoration: 'underline',
                fontFamily: 'Roboto',
                marginTop: '20px',
              }}
            >
              Profile Info
            </h3>
            <div style={{ maxwidth: '600px' }}>
              {/* Art medium row */}
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  // background: '#f2f2f2',
                  padding: '7px',
                }}
              >
                <div
                  style={{
                    flexBasis: '28%',
                    paddingRight: '5px',
                    margin: '0',
                    fontFamily: 'Roboto',
                  }}
                  className="infoText"
                >
                  Art Medium:
                </div>
                <div
                  style={{
                    fontSize: '16px',
                    fontFamily: 'Roboto',
                    flexBasis: '70%',
                  }}
                >
                  {member.artMedium}
                </div>
              </div>
              {/* Can help with row */}
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  background: '#f2f2f2',
                  padding: '7px',
                }}
              >
                <div
                  style={{
                    flexBasis: '28%',
                    paddingRight: '5px',
                    margin: '0',
                    fontFamily: 'Roboto',
                  }}
                  className="infoText"
                >
                  Can help with:
                </div>
                <div
                  style={{
                    fontSize: '16px',
                    fontFamily: 'Roboto',
                    flexBasis: '70%',
                  }}
                >
                  {member.canHelpWith.join(' | ')}
                </div>
              </div>
              {/* Website row */}
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  // background: '#f2f2f2',
                  padding: '7px',
                }}
              >
                <div
                  style={{
                    flexBasis: '28%',
                    paddingRight: '5px',
                    margin: '0',
                    fontFamily: 'Roboto',
                  }}
                  className="infoText"
                >
                  Website:
                </div>
                <div
                  style={{
                    fontSize: '16px',
                    fontFamily: 'Roboto',
                    flexBasis: '70%',
                  }}
                >
                  {member.website}
                </div>
              </div>
              {/* Education row */}
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  background: '#f2f2f2',
                  padding: '7px',
                }}
              >
                <div
                  style={{
                    flexBasis: '28%',
                    paddingRight: '5px',
                    margin: '0',
                    fontFamily: 'Roboto',
                  }}
                  className="infoText"
                >
                  Education/ Experience:
                </div>
                <div
                  style={{
                    fontSize: '16px',
                    fontFamily: 'Roboto',
                    flexBasis: '70%',
                  }}
                >
                  {member.educationExperience}
                </div>
              </div>
              {/* New/returning row */}
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  // background: '#f2f2f2',
                  padding: '7px',
                }}
              >
                <div
                  style={{
                    flexBasis: '28%',
                    paddingRight: '5px',
                    margin: '0',
                    fontFamily: 'Roboto',
                  }}
                  className="infoText"
                >
                  New/ Returning:
                </div>
                <div
                  style={{
                    fontSize: '16px',
                    fontFamily: 'Roboto',
                    flexBasis: '70%',
                  }}
                >
                  {newReturning}
                </div>
              </div>
            </div>
          </section>
          {/* Payment Section */}
          <section>
            <h3
              style={{
                textDecoration: 'underline',
                fontFamily: 'Roboto',
                marginTop: '20px',
              }}
            >
              Payment Info
            </h3>
            <div style={{ maxwidth: '600px' }}>
              {/* Payer row */}
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  // background: '#f2f2f2',
                  padding: '7px',
                }}
              >
                <div
                  style={{
                    flexBasis: '28%',
                    paddingRight: '5px',
                    margin: '0',
                    fontFamily: 'Roboto',
                  }}
                  className="infoText"
                >
                  Payer:
                </div>
                <div
                  style={{
                    fontSize: '16px',
                    fontFamily: 'Roboto',
                    flexBasis: '70%',
                  }}
                >
                  {member.payment.firstName} {member.payment.lastName}
                </div>
              </div>
              {/* Payer email row */}
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  background: '#f2f2f2',
                  padding: '7px',
                }}
              >
                <div
                  style={{
                    flexBasis: '28%',
                    paddingRight: '5px',
                    margin: '0',
                    fontFamily: 'Roboto',
                  }}
                  className="infoText"
                >
                  Payer email:
                </div>
                <div
                  style={{
                    fontSize: '16px',
                    fontFamily: 'Roboto',
                    flexBasis: '70%',
                  }}
                >
                  {member.payment.payerEmail}
                </div>
              </div>
              {/* Amount row */}
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  // background: '#f2f2f2',
                  padding: '7px',
                }}
              >
                <div
                  style={{
                    flexBasis: '28%',
                    paddingRight: '5px',
                    margin: '0',
                    fontFamily: 'Roboto',
                  }}
                  className="infoText"
                >
                  Amount ($):
                </div>
                <div
                  style={{
                    fontSize: '16px',
                    fontFamily: 'Roboto',
                    flexBasis: '70%',
                  }}
                >
                  {member.payment.amount}
                </div>
              </div>
            </div>
          </section>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
