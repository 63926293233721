import React, { Component } from 'react';
import { Link, NavLink, withRouter } from 'react-router-dom';

class NavBar extends Component {
  render() {
    const path = this.props.location.pathname;
    const showHide =
      path === '/membership' ||
      path === '/membership/order' ||
      path === '/docentlogin' ||
      path === '/members'
        ? 'hide'
        : ' ';

    return (
      <nav className={showHide}>
        <ul>
          <li>
            <NavLink to="/gallery">Gallery</NavLink>
          </li>
          <li>
            <NavLink to="/artists">Artists</NavLink>
          </li>
          <li>
            <NavLink to="/events">Events</NavLink>
          </li>
          <li>
            <NavLink to="/about">About</NavLink>
          </li>
          <li>
            <NavLink to="/contact">Contact</NavLink>
          </li>
        </ul>
        <Link to="/join" className="nav-join">
          Join Us
        </Link>
      </nav>
    );
  }
}

export default withRouter(NavBar);
