import React from 'react';

function ImgNextGen({
  srcWebp,
  srcWebpSm,
  fallback,
  alt,
  ...props
}) {



  return (
    <picture>
      {
        (srcWebp && srcWebpSm) && // if webp for regular, md and sm size available in db
          <>
            <source srcset={`${srcWebpSm}`} media="(max-width: 600px)" type="image/webp" />
          </>
      }

      {/* if webp available in db */}
      <source 
        srcSet={srcWebp}
        type="image/webp" 
      />  

      <img src={fallback} alt={alt} {...props} />
    </picture>
  );
}

export default ImgNextGen;