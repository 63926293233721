import http from './httpService';

let apiEndpoint = '/galleries';

export function getGalleryData() {
  return http.get(`${apiEndpoint}`);
}

export function addFeaturedCartSm(formData) {
  return http.post(`${apiEndpoint}/show/artists`, formData)
}

export function updateFeaturedCartSm(formData, featuredCartSmId) {
  return http.put(`${apiEndpoint}/show/artists/${featuredCartSmId}`, formData)
}

export function deleteFeaturedCartSm(featuredCartSmId) {
  return http.delete(`${apiEndpoint}/show/artists/${featuredCartSmId}`)
}