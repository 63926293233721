import React, { Component } from 'react';

class ParagraphWithSlashes extends Component {

  slashes = (collection, i) => {
    const collectionLen = collection.length;
    if (collectionLen !== i + 1) return <span>&nbsp;&nbsp;/&nbsp;&nbsp;</span>
  };

  render() {
    const { text } = this.props;


    return (
      <React.Fragment>
        {/* NOTE: double backslash only, because thats how they get returned from db */}
        {text.split("\n\n").map((paragraph, i) => {
          return (
            <p key={`para-${i}`}>
              {paragraph.split("\n").map((line, j) => {
                return (
                  <React.Fragment key={`line-${j}`}>
                    {line}
                    {/* <span>/</span> */}
                    {this.slashes(paragraph.split("\n"), j)}
                    <br />
                  </React.Fragment>
                );
              })}
            </p>
          );
        })}
      </React.Fragment>
    );
  }
}

export default ParagraphWithSlashes;