import React, { Component } from 'react';
import { addFeaturedCart, updateFeaturedCart } from '../../services/featuredCartService';
import Joi from 'joi-browser';
import { FormControl, FormLabel, TextField, Button, InputLabel, Select, MenuItem  } from '@material-ui/core';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';

class FeaturedCartFrom extends Component {
  state = {
    file: null,
    selectedFile: null,
    cart: {
      id: '',
      alt: '',
      subheading: '',
      mainheading: '',
      maintext: '',
      infotext: '',
      link: '',
      position: ''
    },
    errors: {}
  };

  componentDidMount() {
    window.scrollTo(0, 0);

    const { carts } = this.props.location.state;
    const { id } = this.props.match.params;

    if (id !== 'new') {
      const cart = carts.find(cart => cart._id === id);

      const file = cart.image.src;

      const tempCart = {
        id: cart._id,
        alt: cart.image.alt,
        subheading: cart.subHeading,
        mainheading: cart.heading,
        maintext: cart.text,
        infotext: cart.sideText,
        link: cart.link,
        position: cart.position
      };

      this.setState({
        file: file,
        cart: tempCart
      });
    }
  }

  theme = createMuiTheme ({
    typography: {
      fontFamily: "'Nunito', sans-serif",
      fontSize: 11.5
    },
    palette: {
      primary: {
        main: '#00CCFF'
      },
      secondary: {
        main: '#00CCFF'
      }
    }
  });

  schemaCreate = {
    selectedFile: Joi.object().label('Image'),
    mainheading: Joi.string().max(100).trim().required().label('Main Heading'),
    subheading: Joi.string().required().trim().label('Sub Heading'),
    maintext: Joi.string().max(2000).trim().required().label('Main Text'),
    infotext: Joi.string().allow('').max(200).trim().label('Side Column Text'),
    link: Joi.string().trim().allow(''),
    position: Joi.number().required().label('Cart Position')
  }

  schemaUpdate = {
    selectedFile: Joi.label('Image'),
    mainheading: Joi.string().max(100).trim().required().label('Main Heading'),
    subheading: Joi.string().required().trim().label('Sub Heading'),
    maintext: Joi.string().max(2000).trim().required().label('Main Text'),
    infotext: Joi.string().allow('').max(200).trim().label('Side Column Text'),
    link: Joi.string().trim().allow(''),
    position: Joi.number().required().label('Cart Position')
  }

  validate = () => {
    const { id } = this.props.match.params;
    let result = '';

    const toBeValidated = {
      selectedFile: this.state.selectedFile,
      mainheading: this.state.cart.mainheading,
      subheading: this.state.cart.subheading,
      maintext: this.state.cart.maintext,
      infotext: this.state.cart.infotext,
      link: this.state.cart.link,
      position: this.state.cart.position
    }

    if (id === 'new') {
      result = Joi.validate(toBeValidated, this.schemaCreate, { abortEarly: false });
      if (!result.error) return null;
    } else {
      result = Joi.validate(toBeValidated, this.schemaUpdate, { abortEarly: false });
      if (!result.error) return null;
    }

    const errors = {};
 
    for (let item of result.error.details) {
      errors[item.path[0]] = item.message;
    }

    return errors;
  };


  fileSelectedHandler = e => {
    if (e.target.files.length !== 0)
      this.setState({
        selectedFile: e.target.files[0],
        file: URL.createObjectURL(e.target.files[0])
      })
  }


  handleChange = e => {
    const cart = { ...this.state.cart };
    cart[e.target.name] = e.target.value;

    this.setState({ cart });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    
    const { id } = this.props.match.params;

    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) return;

    const setFeaturedCart = async () => {
      
      const formData = new FormData();

      // if ((id !== 'new') && (this.state.selectedFile === null)) { // If updating, and no image present, skip adding image to form.

      // } else {
      //   formData.append('featCartImage', this.state.selectedFile, this.state.selectedFile.name);
      // }

      if ((id === 'new') || (this.state.selectedFile !== null))  // Only add image to form, if a new cart is created OR if adding new image to existing cart.
        formData.append('featCartImage', this.state.selectedFile, this.state.selectedFile.name);

      formData.append('image[alt]', this.state.cart.alt);
      formData.append('subHeading', this.state.cart.subheading);
      formData.append('heading', this.state.cart.mainheading);
      formData.append('text', this.state.cart.maintext);
      formData.append('sideText', this.state.cart.infotext);
      formData.append('link', this.state.cart.link);
      formData.append('position', this.state.cart.position);



      if (id === 'new') {
        // create
        try {
          await addFeaturedCart(formData);
          window.location = '/';
        } catch (ex) {
          console.log(ex) // TODO: take out? axios interceptor should handle unexpected errors. Try/catch only needed for expected errors, eg 404
        }
      } else {
        // update
        try {
          await updateFeaturedCart(formData, id);
          window.location = '/';
        } catch (ex) {
          if (ex.response && ex.response.status === 404)
            window.location = '/';
          console.log(ex) // TODO: take out? axios interceptor should handle unexpected errors. Try/catch only needed for expected errors, eg 404
        }
      }

    };
    setFeaturedCart();

    // console.log('Submitted');
  }

  render() {
    if (!this.props.location.state) window.location = '/';

    const { cart, errors } = this.state;
    const { carts } = this.props.location.state; // Carts arr received from featuredCarts component.
    const { id } = this.props.match.params;

    return (
      <ThemeProvider theme={this.theme}>
        <div className="margin-container-sm crud-form">
          <div className="crud-form-col">
            <h1>Featured Cart Form</h1>

            <form className="membership-form" onSubmit={this.handleSubmit}>

              <FormLabel style={{ fontSize: '1.1rem', fontWeight: 600, color: '#000' }} >Image Upload</FormLabel>
              <div className="input-wrap">
                <div className="img-upload">
                  <Button className="img-upload-button" variant="contained" component="label">
                    Choose Image
                    <input type="file" hidden onChange={this.fileSelectedHandler}/>
                  </Button>
                  <div className="image-preview">
                    <img src={this.state.file} alt="" />
                  </div>
                </div>
                {errors.selectedFile && <React.Fragment><br/><div style={{ fontSize: '.8rem', fontWeight: '600', color: '#f44336' }}>"Image" is not allowed to be empty</div><br/></React.Fragment>}
                <TextField value={cart.alt} onChange={this.handleChange} id="alt" name="alt" type="text" label="Alternative Text"  style={{ marginTop: '1.5em', width: '100%' }}/>
                <div style={{ fontSize: '.8rem', fontWeight: '600', color: '#007bff'}}>
                  Describe the image to a visual impaired person as <span style={{ textDecoration: 'underline' }}>precisely</span> as possible with as <span style={{ textDecoration: 'underline' }}>few words</span> possible.
                </div>
              </div>

              <FormLabel style={{ fontSize: '1.1rem', fontWeight: 600, color: '#000' }} >Text</FormLabel>
              <div className="input-wrap">
                <TextField error={!!errors.mainheading} helperText={errors.mainheading} value={cart.mainheading} onChange={this.handleChange} id="mainheading" name="mainheading" type="text" label="Main Heading"  style={{ width: '100%' }}/>
                <TextField error={!!errors.subheading} helperText={errors.subheading}value={cart.subheading} onChange={this.handleChange} id="subheading" name="subheading" type="text" label="Sub Heading"  style={{ marginTop: '2em', width: '100%' }}/>
                <div style={{ fontSize: '.8rem', fontWeight: '600', color: '#007bff'}}>
                  Examples: "on view", "upcoming",  "visit us in the gallery" etc...
                </div>
                <TextField error={!!errors.maintext} helperText={errors.maintext} value={cart.maintext} onChange={this.handleChange} id="maintext" name="maintext" type="text" label="Main Text"  multiline rows={5} rowsMax={10} style={{ marginTop: '2em', width: '100%' }}/>
                <TextField value={cart.infotext} onChange={this.handleChange} id="infotext" name="infotext" type="text" label="Side Column"  multiline rows={3} rowsMax={10} style={{ marginTop: '2em', width: '100%' }}/>
                <div style={{ fontSize: '.8rem', fontWeight: '600', color: '#007bff'}}>
                  Use for Dates and Times. Can be left empty. 
                </div>
              </div>

              <FormLabel style={{ fontSize: '1.1rem', fontWeight: 600, color: '#000' }} >Link</FormLabel>
              <div className="input-wrap">  
                <div style={{ fontSize: '.8rem', fontWeight: '600', color: '#007bff'}}>
                  If linking to <span style={{ textDecoration: 'underline' }}>internal page</span>: Link has to start with &nbsp;/ (forward-slash) <br/>
                  <span style={{ fontWeight: '300', color: '#000'}}>Example: &nbsp;/events</span> <br/>
                  If linking to <span style={{ textDecoration: 'underline' }}>external page</span>: Link has to start with &nbsp;https:// <br/>
                  <span style={{ fontWeight: '300', color: '#000'}}>Example: &nbsp;https://google.com</span> <br/>
                  If <span style={{ textDecoration: 'underline' }}>no link</span> needed. Leave empty.
                </div>
                <TextField value={cart.link} onChange={this.handleChange} id="link" name="link" type="text" label="Link"  style={{ marginTop: '1.3em', width: '100%' }}/>
              </div>

              <FormLabel style={{ fontSize: '1.1rem', fontWeight: 600, color: '#000' }} >Cart Position</FormLabel>
              <div className="input-wrap">
                <FormControl >
                  <InputLabel id="position-label">Position</InputLabel>
                  <Select
                    labelId="position-label"
                    value={this.state.cart.position}
                    name="position"
                    onChange={this.handleChange}
                    style={{width: '200px'}}
                    inputProps={{MenuProps: {disableScrollLock: true}}}
                    error={!!errors.position}
                  >

                  { carts.map((cart, index) => (
                    <MenuItem key={cart._id} value={index+1}>{index+1}</MenuItem>
                  ))}

                  {/* Add additional select item, only when adding a new cart. */}
                  { (id === 'new') && <MenuItem value={carts.length+1} >{carts.length+1}</MenuItem> } 

                  </Select>
                </FormControl>
                {errors.position && <React.Fragment><br/><div style={{ fontSize: '0.6160714285714286rem', fontWeight: '400', color: '#f44336', marginTop: '3px'}}>Select a "Cart Position"</div><br/></React.Fragment>}
              </div>

              <Button type="submit" variant="contained" color="primary" style={{borderRadius: '4px', marginTop: '1.5em', width: '200px', height: '50px', fontSize: '1.1rem', fontWeight: '600'}}>
                Save
              </Button>

            </form>


          </div>
        </div>
      </ThemeProvider>
    );
  }
}

export default FeaturedCartFrom;