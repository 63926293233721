import http from './httpService';

let apiEndpoint = '/featuredCarts';

export function getFeaturedCarts() {
  return http.get(apiEndpoint);
}

export function addFeaturedCart(formData) {
  return http.post(apiEndpoint, formData)
}

export function updateFeaturedCart(formData, featuredCartId) {
  return http.put(`${apiEndpoint}/${featuredCartId}`, formData)
}

export function deleteFeaturedCart(featuredCartId) {
  return http.delete(`${apiEndpoint}/${featuredCartId}`)
}


