import React, { Component } from 'react';
import FeaturedCart from "./featuredCart";
import { getFeaturedCarts } from './../services/featuredCartService';
import { Link } from 'react-router-dom';

class FeaturedCarts extends Component {
  state = {
    carts: []
  };

  componentDidMount() {
    const setStateCarts = async () => {
      const result = await getFeaturedCarts();
      this.setState({ carts: result.data });
    };
    setStateCarts();
  }

  render() {
    const { carts } = this.state;
    const { user } = this.props;

    return ( 
      <section>
        {/* Use of Ternary, other wise if user logged out, we try to access null.  */}
        {/* { user ? (user.isAdmin || user.role === 'featuredCartsAdmin') && */}
        { user ? (user.isAdmin || user.role?.some((elem) => ['featuredCartsAdmin'].includes(elem))) &&
          <Link 
            className="admin-btn admin-btn-create"
            to={{
              pathname: "/featuredcarts/new",
              state: {
                carts: carts
              }
            }}
          >
            New Cart
          </Link> : null
        }

        <div className="featuredCarts-grid-2-col">
          {carts.map((cart) => (
            <FeaturedCart cart={cart} key={cart._id} carts={carts} user={user} />
          ))}
        </div>
      </section>
     );
  }
}
 
export default FeaturedCarts;