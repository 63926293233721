import http from './httpService';

let apiEndpoint = '/events';

export function getEvents() {
  return http.get(apiEndpoint);
}

export function getEventByName(name) {
  return http.get(`${apiEndpoint}/${name}`);
}