import React, { Component } from 'react';

class GallerySideInfo extends Component {

  render() { 
    const { additionalInfo, hours } = this.props.galleryData;

    let dailyHoursObj = {};
    if (hours) dailyHoursObj = hours.days;

    return ( 
      <div className="aboutCart-sideInfo">
        <div className="aboutCart-sideInfo-hours">

          { hours && hours.heading &&  <h4>{hours.heading}</h4> }

          { dailyHoursObj && 
          
              Object.keys(dailyHoursObj).map((day) => (
                <div key={day}>{day}&nbsp; | &nbsp; {dailyHoursObj[day].open} to {dailyHoursObj[day].close}</div>
              ))
            
          }

        </div>

        { additionalInfo && 
            <React.Fragment>
              <h4>{additionalInfo.heading}</h4>
              <p>{additionalInfo.text}</p>
            </React.Fragment>
        }
      </div>
    );
  }
}

export default GallerySideInfo;