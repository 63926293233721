import React from 'react';
import { Button } from '@material-ui/core';

function MembershipChoice({hideMailOrOnline, showMail}) {
  return (
    <div className="membership-form-col">
      {/* <div style={{ fontSize: '1.1rem', fontWeight: 600, color: '#000' }} >
        Join online or by mail?
      </div> */}
      <div className="input-wrap">
        <div style={{display: 'flex', justifyContent: 'space-evenly'}}>
          <Button onClick={hideMailOrOnline} type="button" variant="contained" color="primary" style={{fontSize: '.9rem', borderRadius: '4px', margin:'.7em'}}>
            Join Online
          </Button>

          <Button onClick={() => showMail(true)} type="button" variant="contained" color="primary" style={{fontSize: '.9rem', borderRadius: '4px', margin:'.7em'}}>
            Join by Mail
          </Button>
        </div>
      </div>
    </div>
  );
}

export default MembershipChoice;