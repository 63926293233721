import React, { Component } from 'react';

class LocationMap extends Component {
  state = {  }
  render() { 
    return ( 
      <section>
        <h2>Visit Us</h2>
        <div className="gettingHere-row">
          <div className="gettingHere-iframe-wrap">
            <iframe className="gettingHere-iframe" title="google map with pin at tahoe art league" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d12412.876431763696!2d-119.98184141819846!3d38.94182253388725!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80998ffcfdcd22bf%3A0xa6414b1f1cc75244!2sTahoe%20Art%20League&#39;s%20Art%20Center%20Gallery!5e0!3m2!1sen!2sde!4v1614877003271!5m2!1sen!2sde" style={{border:0}} allowFullScreen="" loading="lazy"></iframe>
          </div>
          <div className="gettingHere-info-wrap">
            <h3>Location</h3>
            <p>
              The Gallery is located at <a href="https://goo.gl/maps/YRXxqZf4VjLrp8ba9">3062 Lake Tahoe Blvd</a> in South Lake Tahoe, California.
            </p>
            <h3>Parking</h3>
            <p>FREE parking is available in the Gallery parking lot, as well as metered spaces on nearby streets. In the event that the Gallery parking lot is full, use this map for additional parking options.</p>
          </div>
        </div>
      </section>
    );
  }
}
 
export default LocationMap;