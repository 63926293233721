import React, { Component } from 'react';
import Crossfade from './crossfade';
import FeaturedCarts from './featuredCarts';
import { getCrossfadeImagesByName } from './../services/crossfadeService';
import BannerText from './bannerText';
import { getBannerTextByName } from './../services/bannerTextService';

class Home extends Component {
  state = {
    crossfadeImages: [],
    bannerText: {
      name: '',
      mainText: '',
      arrowText: '',
      link: '',
      showBanner: true
    }
  };

  componentDidMount() {
    window.scrollTo(0, 0);

    const setStateCrossfadeImages = async () => {
      const result = await getCrossfadeImagesByName('landing');
      let imgArr = result.data[0].images;

      // Sort images by postion property.
      imgArr.sort((a,b) => (a.position > b.position) ? 1 : ((b.position > a.position) ? -1 : 0))

      this.setState({ crossfadeImages: imgArr });
    };
    setStateCrossfadeImages();

    const getBannerTextThenSetState = async () => {
      const result = await getBannerTextByName('landing');
      const bannerText = result.data;

      this.setState({ bannerText });
    };
    getBannerTextThenSetState();
  }

  
  render() {
    const { user } = this.props;
    const { bannerText } = this.state;

    return ( 
      <React.Fragment>
        <div className="margin-container">
          <Crossfade crossfadeImages={this.state.crossfadeImages} />
        </div>

        { bannerText.name && <BannerText bannerText={bannerText} />}  {/* Only render if data fetched. */}
        
        <div className="margin-container">
          <FeaturedCarts user={user} /> 
        </div>
      </React.Fragment>
    );
  }
}

export default Home;