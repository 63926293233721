import React, { Component } from 'react';
import Paragraph from './paragraph';

class Paragraphs extends Component {
  render() {
    const { paragraphs } = this.props;

    return (
      <React.Fragment>
        {paragraphs.map((para, index) => (
          
          <React.Fragment key={para._id}>

            {/* Don't render heading of 1st para. */}
            { index !== 0 && 
                <h2>{para.heading}</h2>
            }
            {/* <p key={para._id}>{para.text}</p> */}
            <Paragraph text={para.text}/>
          </React.Fragment>

        ))}
      </React.Fragment>

    );
  }
}

export default Paragraphs;