import React, { Component } from 'react';
import { withRouter} from 'react-router-dom'; // Needed for redirect
import OrderConfirmationText from './orderConfirmationText';

class orderConfirmation extends Component {
  render() {
    const { history, location } = this.props;
    const { memberInfo } = location;

    if (history.action !== 'REPLACE') history.replace('/not-found'); // TODO: Better way to handle this?

    return (
      <section className="orderConfirmation" style={{ textAlign: 'center', paddingTop: 90 }}>
        <div className="margin-container-sm">
          <OrderConfirmationText memberInfo={memberInfo} />
        </div>
      </section>
    );
  }
}

export default withRouter(orderConfirmation);