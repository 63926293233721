import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import NavBurgerLines from './navBurgerLines';

class NavBurger extends Component {

  render() {
    let activeClass = '';
    activeClass = (this.props.navMobActive) ? 'burgerActive' : ' ';

    const path = this.props.location.pathname;
    const showHide = (path === '/membership' || path === '/membership/order' || path === '/docentlogin') ? 'hide' : ' ';

    return ( 
      <div className={`navBurger-container ${activeClass} ${showHide}`} onClick={this.props.toggleNavMobActive}>
        <NavBurgerLines activeClass={activeClass} />
        {/* <div className={`navBurger ${activeClass}`}>
          <div className="navBurger-line"></div>
          <div className="navBurger-line"></div>
          <div className="navBurger-line"></div>
        </div> */}
      </div>
    );
  }
}

export default withRouter(NavBurger);